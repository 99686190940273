import {Route} from '../helpers/Route';

export enum SearchKey {
  type = 'type',
  from = '',
  to = '',
}

export const route = {
  //redirect route
  redirectToLogin: Route.of({path: '/redirect-to-login-page'}),

  //private routes
  dashboard: Route.of({path: '/'}),
  createCodeGroup: Route.of({path: '/create-code-group'}),
  editCodeGroup: Route.of<{id: string}>({path: '/edit-code-group/:id'}),
  viewCodeGroup: Route.of<{id: string}>({path: '/view-code-group/:id'}),
  settings: Route.of({path: '/settings'}),
};

export const routePaths = {
  viewCodeGroup: 'view-code-group',
  editCodeGroup: 'edit-code-group',
  createCodeGroup: 'create-code-group',
};
