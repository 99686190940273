import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Typography} from 'antd';
import {CodeAccessTypes} from '../../types/codes';
import {defaultTheme} from '../../ui-kit/theme/theme';
import trees from '../../ui-kit/icons/treesgold.svg';

const {Text} = Typography;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 190px 32px 190px;
  background-image: url(${trees});
  background-position: right bottom;
  background-repeat: no-repeat;
  ${Media.down.xxl} {
    padding: 54px 90px 32px 90px;
  }
`;

export const TitleLine = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatusBlock = styled.div`
  display: flex;
  align-items: center;
`;

const getStatusColor = (status?: CodeAccessTypes) => {
  if (status === CodeAccessTypes.sent) return defaultTheme.palette.common.green;
  return defaultTheme.palette.common.primaryBlack;
};

export const StatusText = styled(Text)<{status?: CodeAccessTypes}>`
  & > span {
    color: ${({status}) => getStatusColor(status)};
    text-transform: capitalize;
  }
  padding-right: 15px;
`;
