import React, {useEffect, useState} from 'react';
import {ModalLvl} from '../../components/CodeGroup/View/Table';
import {Modal} from 'antd';
import {ModalDescr} from '../../components/Dashboard/styles';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {CodeAccessTypes} from '../../types/codes';

type ShareModalProps = {
  clientName?: string;
  clientEmail?: string;
  clientEmailList?: string[];
  modalLvl: ModalLvl;
  setModalLvl: (lvl: ModalLvl) => void;
  onSuccess?: () => Promise<{res: boolean; error: string} | void>;
  status?: CodeAccessTypes;
  totalQtyNotSentEmails?: () => string;
  emails?: any;
};

export const ShareModal: React.FC<ShareModalProps> = ({
  clientEmail,
  clientEmailList,
  clientName,
  modalLvl,
  setModalLvl,
  onSuccess,
  status,
  totalQtyNotSentEmails,
}) => {
  const [error, setError] = useState<string | undefined>('');
  const totalEmails = totalQtyNotSentEmails?.();

  useEffect(() => {
    totalQtyNotSentEmails?.();
  }, []);
  const emails = clientEmailList?.length ? clientEmailList.join(', ') : clientEmail;
  useEffect(() => {
    const isShare = status === CodeAccessTypes.notsent;
    const isReshare = status === CodeAccessTypes.sent;
    const confTitle = isShare
      ? `Are you ready to send a magic link to the ${clientName} at ${emails}?`
      : isReshare
      ? `Are you sure you want to reshare access with the ${clientName} at ${emails}?`
      : `Are you sure you would like to bulk send ${totalEmails} of codes?`;
    const confDescr = isShare
      ? `With this action, the client will be provided access to their client portal and allowed to distribute codes.`
      : isReshare
      ? `Upon confirming, the client will receive their Magic Link with access to this promo code group in their inbox.`
      : `Please make sure the emails are correct. With this action, your guests will receive emails with their assigned promo codes. This cannot be undone.`;

    const successTitle = isReshare
      ? `The access is reshared successfully!`
      : isShare
      ? `The access is shared successfully!`
      : `The promo codes have been shared successfully!`;
    const successDescr =
      isShare || isReshare
        ? `The client will receive their Magic Link momentarily. They will have access to their promo codes portal and distribute codes at their own discretion.`
        : `Your guests will receive an email with Enchant Magic Link that will allow them to enjoy the promo code!`;
    if (modalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: confTitle,
        content: <ModalDescr>{confDescr}</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <QuestionCircleOutlined />,
        onOk: async () => {
          const res = await onSuccess?.();
          if (res?.res) return setModalLvl(ModalLvl.success);
          setModalLvl(ModalLvl.error);
          setError(res?.error);
        },
        onCancel: () => setModalLvl(ModalLvl.closed),
      });
    }
    if (modalLvl === ModalLvl.success) {
      Modal.success({
        title: successTitle,
        content: <ModalDescr>{successDescr}</ModalDescr>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setModalLvl(ModalLvl.closed);
        },
      });
    }
    if (modalLvl === ModalLvl.error) {
      Modal.error({
        title: `Error!`,
        content: <ModalDescr>{error}</ModalDescr>,
        okText: 'Close',
        autoFocusButton: null,
        onOk: () => {
          setModalLvl(ModalLvl.closed);
          setError('');
        },
      });
    }
  }, [modalLvl]);

  return <></>;
};
