import React, {useEffect, useState} from 'react';
import {Table, TableTitle} from '../styles';
import {TableWrapper} from '../../../ui-kit/Table';
import {DepartmentTableColumnsTitles, DepartmentTableFields, TableColumnT} from '../../../helpers/table';
import {ActionsItem, ActionsItemProps, DepartmentTypesItem, DepartmentTypesProps} from './TableItems';
import {ModalLvl} from '../../CodeGroup/View/Table';
import {Modal} from 'antd';
import {ModalDescr} from '../../Dashboard/styles';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {DepTableItemT} from '../../../types/settings';
import {DepartmentFormT} from '../../../types/formData';
import {CreateDepartmentField} from '../../../queries/types/settings';
import {updateDepValues} from '../../../hooks/settings';
import {CenteredLoader} from '../../../ui-kit/Loader';

type DepartmentTablePropsT = {
  data: DepTableItemT[];
  onCreate: (v: DepartmentFormT) => void;
  onDelete: (id?: string) => void;
  onUpdate: (v: updateDepValues) => void;
  loading?: boolean;
};

export const DepartmentTable: React.FC<DepartmentTablePropsT> = ({data, onCreate, onDelete, loading}) => {
  const [addName, setAddName] = useState<string>('');

  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [isEditing, setIsEditing] = useState(false);
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [addModalLvl, setAddModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (b: boolean) => setIsEditing(b);

  const handleChangeName = (e: any) => {
    setAddName(e?.target.value);
  };
  const onAdd = async () => {
    await onCreate({[CreateDepartmentField.name]: addName});
    setAddModalLvl(ModalLvl.success);
    setAddName('');
  };
  const columns: TableColumnT[] = [
    {
      key: DepartmentTableFields.name,
      dataIndex: DepartmentTableFields.name,
      title: DepartmentTableColumnsTitles.types,
      render: (props: DepartmentTypesProps) => {
        return <DepartmentTypesItem {...props} addName={addName} onChange={handleChangeName} />;
      },
    },
    {
      key: DepartmentTableFields.actions,
      dataIndex: DepartmentTableFields.actions,
      title: DepartmentTableColumnsTitles.action,
      render: (props: ActionsItemProps) => (
        <ActionsItem
          {...props}
          onAdd={onAdd}
          configType={'department'}
          setDelPopup={setDelPopup}
          setFocusId={setFocusId}
          setEditing={setEditing}
          focusedId={focusedId}
          isEditing={isEditing}
        />
      ),
    },
  ];
  useEffect(() => {
    const depName = data.find((el) => el.id?.id === focusedId)?.name?.name || 'department';
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `You are request to remove ${depName}?`,
        content: <ModalDescr>Upon confirming, the department will be removed from the system.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <QuestionCircleOutlined />,
        onOk: () => {
          onDelete?.(focusedId);
          setDelPopup(ModalLvl.success);
        },
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `${depName} is removed!`,
        content: <ModalDescr>The department has been removed from the system.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  useEffect(() => {
    if (addModalLvl === ModalLvl.success) {
      Modal.success({
        title: `Success!`,
        content: <ModalDescr>The department has been added to the system.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          setAddModalLvl(ModalLvl.closed);
        },
      });
    }
  }, [addModalLvl]);

  return (
    <>
      <TableTitle level={3}>Department</TableTitle>
      <TableWrapper>
        <Table columns={columns} dataSource={data} pagination={false} />
        {loading && <CenteredLoader />}
      </TableWrapper>
    </>
  );
};
