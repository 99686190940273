import {atom} from 'recoil';

export type CreatingGroupType = {
  id?: string;
  clientName?: string;
  ticketType?: string;
  department?: string;
  contactPerson?: string;
  createdOn?: Date;
  totalCodes?: number;
  codesSent?: number;
  status?: 'Creating';
  isCreated?: boolean;
} | null;

export const creatingGroup = atom<CreatingGroupType>({
  key: 'creatingGroup',
  default: null,
});
