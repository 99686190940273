import {postQuery} from './hooks';
import {CodesQueryList} from '../constants/api';
import {sendNotSentGuestEmailsReq, uploadGuestEmailsFields, uploadGuestEmailsReq} from './types/emails';

export const uploadGuestEmailsQuery = async ({groupId, body}: uploadGuestEmailsReq) =>
  await postQuery(CodesQueryList.uploadGuestEmails(groupId)).field(
    uploadGuestEmailsFields.guestEmails,
    body.guestEmails,
  );

export const sendNotSentGuestEmailsQuery = async ({groupId}: sendNotSentGuestEmailsReq) =>
  await postQuery(CodesQueryList.sendNotSentGuestEmails(groupId));
