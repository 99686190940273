import React from 'react';
import {
  CreateButton,
  DashboardActions,
  DashboardCard,
  DashboardCardsWrapper,
  DashboardHeaderWrapper,
  PageWrapper,
  PaginationWrapper,
} from './styles';
import {DashboardCardT, DashboardTableDataT, PaginationObjT} from '../../types/dashboard';
import {route} from '../../constants/routes';
import {DashboardTable} from './Table';
import {MetricsT} from '../../queries/types/codes';
import {Pagination, Spin} from 'antd';

type DashboardPageProps = {
  codes?: DashboardTableDataT[];
  goEdit: (id: string) => void;
  onDelete: (id: string) => void;
  loading?: boolean;
  metrics: MetricsT;
  canAddEdit: boolean;
  searchCodes: (v: string) => void;
  pagination: PaginationObjT;
  metricsLoading?: boolean;
  isSearching?: boolean;
};
const pageSizeOptions = [10, 25, 50, 100];
export const DashboardPage: React.FC<DashboardPageProps> = ({
  codes,
  goEdit,
  onDelete,
  loading,
  metrics,
  canAddEdit,
  searchCodes,
  pagination,
  metricsLoading,
  isSearching,
}) => {
  const cards: DashboardCardT[] = [
    {
      label: 'Total Code Groups',
      value: String(codes?.length),
    },
    {
      label: 'Codes Uploaded',
      value: String(metrics?.codesUploaded),
    },
    {
      label: 'Codes Sent',
      value: String(metrics?.codesSent),
      tooltip: 'The total numbers of codes that clients send guests.',
    },
    // {
    //   label: 'Codes Used',
    //   value: String(metrics?.codesUsed),
    //   tooltip: 'The total numbers of codes that guests check out ticket orders with codes applied',
    // },
  ];

  return (
    <PageWrapper>
      <DashboardHeaderWrapper>
        <Spin spinning={metricsLoading}>
          <Cards cards={cards} />
        </Spin>
        <DashboardActions>
          {canAddEdit && <CreateButton to={route.createCodeGroup.path}>Create New Group</CreateButton>}
        </DashboardActions>
      </DashboardHeaderWrapper>

      <DashboardTable
        data={codes}
        goEdit={goEdit}
        onDelete={onDelete}
        loading={loading}
        canAddEdit={canAddEdit}
        searchCodes={searchCodes}
        metricsLoading={metricsLoading}
      />
      <PaginationWrapper>
        {!isSearching && (
          <Pagination
            showSizeChanger
            onShowSizeChange={pagination.changeLimit}
            current={pagination.pageNumber}
            onChange={pagination.changePage}
            total={pagination.total}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </PaginationWrapper>
    </PageWrapper>
  );
};

const Cards: React.FC<{cards: DashboardCardT[]}> = ({cards}) => {
  return (
    <DashboardCardsWrapper>
      {cards.map((el, i) => (
        <DashboardCard label={el.label} value={el.value} tooltip={el.tooltip} key={el?.label || i} />
      ))}
    </DashboardCardsWrapper>
  );
};
