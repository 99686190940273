import React, {useEffect, useState} from 'react';
import {PageWrapper, TitleLine} from '../styles';
import {
  CodeForm,
  CodeFormItem,
  FormButton,
  GroupTitle,
  StyledSelect,
  TooltipWrapper,
  ErrorLine,
  ErrorText,
  SuccessLine,
  SuccessText,
} from './styles';
import {LinkBack} from '../../../ui-kit/Button/LinkBack';
import {CodeCreateFields, FormMode} from '../../../types/codes';
import Input, {TextArea} from '../../../ui-kit/Input';
import {Tooltip} from '../../../ui-kit/Tooltip';
import {SelectOptionT} from '../../../types/helpers';
import {FileUpload} from '../../../ui-kit/Input/FileUpload';
import {FormTips} from './tips';
import {useCreateCodesT} from '../../../hooks/codes';
import {Form as FormAntd, Modal, Typography, message} from 'antd';
import {palette} from '../../../ui-kit/theme/palette';
import {codeGroupBody, CodeGroupT} from '../../../queries/types/codes';
import {User} from '../../../types/auth';
import {Loader} from '../../../ui-kit/Loader';
import {SelectWithCheckbox} from '../../../ui-kit/Select';
import {route} from '../../../constants/routes';
import {QuestionCircleOutlined, WarningOutlined} from '@ant-design/icons';
import {ModalDescr} from '../../Dashboard/styles';
import {popupLvl} from '../../Dashboard/Table';
import {NavigateFunction} from 'react-router-dom';
import {CodeAccessTypes} from '../../../ui-kit/helpers/codes';
import {parseStringArray, validateEmailsArray} from '../../../helpers/helpers';
const {Text} = Typography;

export type CodeFormProps = {
  mode: FormMode;
  formData: useCreateCodesT;
  initial?: Partial<codeGroupBody>;
  isLoading?: boolean;
  navigate: NavigateFunction;
  codeGroup?: CodeGroupT;
  user?: User | null;
  isAssociate?: boolean;
  isDeptManager?: boolean;
  isSuperAdmin?: boolean;
  isFromDetailed?: boolean;
  groupId?: string;
};
const formTitles = {create: 'Create Promo Code Group', edit: 'Update Client Profile'};
const formButtons = {create: 'Create Promo Code Group', edit: 'Save Changes'};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: 'Invalid email. Please enter a valid email.',
  },
};

type ItemLabelProps = {
  label: string;
  title: string | React.ReactNode;
};

const ItemLabel: React.FC<ItemLabelProps> = ({label, title}) => {
  return (
    <>
      <span>{label}</span>
      <TooltipWrapper>
        <Tooltip title={<Text>{title}</Text>} color={palette.common.white} />
      </TooltipWrapper>
    </>
  );
};

export const Form: React.FC<CodeFormProps> = ({
  mode,
  formData,
  initial,
  isLoading,
  navigate,
  user,
  isAssociate,
  isDeptManager,
  isSuperAdmin,
  codeGroup,
  isFromDetailed,
  groupId,
}) => {
  const {onCreate, error, success, id, options} = formData;
  const [form] = FormAntd.useForm();

  const [fileList, setFileList] = useState([]);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<any>({});
  const [department, setDepartment] = useState<string>();
  const [associates, setAssociates] = useState<SelectOptionT[]>([]);
  const [edited, setEdited] = useState(false);

  const title = formTitles[mode];
  const button = formButtons[mode];
  const isCreate = mode === 'create';

  const [delPopupLvl, setDelPopupLvl] = useState<popupLvl>(popupLvl.closed);
  const setDelPopup = (lvl: popupLvl) => setDelPopupLvl(lvl);
  const openLeaveModal = (e: any) => {
    e.preventDefault();
    if (success?.status === 'completed' || !edited) {
      if (isFromDetailed && groupId) return navigate(route.viewCodeGroup.get({id: groupId}));
      return navigate(route.dashboard.path);
    }
    if (mode === 'edit') return setDelPopup(popupLvl.confirmLeave);
    return navigate(route.dashboard.path);
  };
  const goDetailView = (id: string) => navigate(route.viewCodeGroup.get({id}));

  const disabled =
    codeGroup?.outboundStatus === CodeAccessTypes.sent && codeGroup?.groupType === 'external' && !isSuperAdmin;
  const disabledLocation = codeGroup?.outboundStatus === CodeAccessTypes.sent && codeGroup?.groupType === 'external';
  const disabledClientName = disabled && !isDeptManager && !isAssociate;
  const disableDepartment = isAssociate
    ? true
    : codeGroup?.outboundStatus === CodeAccessTypes.sent && codeGroup?.groupType === 'external' && !isSuperAdmin;
  const disableAssign = isAssociate
    ? true
    : codeGroup?.outboundStatus === CodeAccessTypes.sent &&
      codeGroup?.groupType === 'external' &&
      !isDeptManager &&
      !isSuperAdmin;

  useEffect(() => {
    if (isCreate) {
      setDepartment(user?.departments && user?.departments[0]);
    } else {
      setDepartment(initial?.department);
    }
  }, [initial?.department, user?.departments]);

  useEffect(() => {
    if (!department) return;
    const associatesByDepartment = options.associates.filter((el) => el.departments?.includes(department));
    setAssociates(associatesByDepartment);
  }, [department, !!options.associates.length]);

  useEffect(() => {
    if (delPopupLvl === popupLvl.confirm) {
      Modal.confirm({
        title: mode === 'create' ? `Are you ready to create this group?` : `Are you ready to update this group?`,
        content: <ModalDescr>Please make sure all the details are correct.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <QuestionCircleOutlined />,
        onOk: () => {
          onCreate?.({...values, promoCodes: values?.promoCodes?.file});
          if (!error) {
            setLoading(true);
          }
          setDelPopup(popupLvl.success);
        },
        onCancel: () => setDelPopup(popupLvl.closed),
      });
    }
    if (delPopupLvl === popupLvl.success) {
      Modal.success({
        title: mode === 'create' ? `This group was created successfully!` : `This group was updated successfully!`,
        content: <ModalDescr>You can now view this group and send to the client.</ModalDescr>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setDelPopup(popupLvl.closed);
        },
      });
    }
    if (delPopupLvl === popupLvl.confirmLeave) {
      Modal.confirm({
        title: 'Are you sure you want to leave edit mode?',
        content: <ModalDescr>If you decide to leave your changes will not be saved.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => {
          setDelPopup(popupLvl.closed);
          if (isFromDetailed && groupId) return navigate(route.viewCodeGroup.get({id: groupId}));
          return navigate(route.dashboard.path);
        },
        onCancel: () => setDelPopup(popupLvl.closed),
      });
    }
  }, [delPopupLvl]);

  useEffect(() => {
    if (success?.status === 'completed' && id) goDetailView(id);
  }, [success, id]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  const optionsGroup: SelectOptionT[] = [
    {label: 'Internal', value: 'internal'},
    {label: 'External', value: 'external'},
  ];

  const ticketTypeOptions = [
    {label: 'General Admission', value: 'GA'},
    {label: 'Parking', value: 'Parking'},
    {label: 'Preferred Parking', value: 'Preferred Parking'},
    {label: 'Valet Parking', value: 'Valet Parking'},
    {label: 'Private Space Rental', value: 'Private Space Rental'},
    {label: 'Private Suites', value: 'Private Suites'},
    {label: 'VIP Experience', value: 'VIP Experience'},
    {label: 'Ice Skating', value: 'Skating'},
    {label: 'Dinner', value: 'Dinner'},
    {label: 'Brunch', value: 'Brunch'},
    // {label: 'Elf Guide', value: 'Elf Guide'},
    // {label: 'Private Santa', value: 'Private Santa'},
    // {label: 'Private Photography', value: 'Private Photography'},
    // {label: 'Cash Cards', value: 'Cash Cards'},
  ];
  const resetError = () => setFileTypeError(false);
  const props = {
    onRemove: (file: any) => {
      // eslint-disable-next-line
      //@ts-ignore
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileTypeError(false);
    },
    // eslint-disable-next-line
    //@ts-ignore
    beforeUpload: (file) => {
      console.log(file);
      setFileTypeError(false);
      if (file.type !== 'text/csv') {
        setFileTypeError(true);
        return false;
      }
      // eslint-disable-next-line
      //@ts-ignore
      setFileList([...fileList, file]);
      return false;
    },
    error: fileTypeError
      ? {
          title: 'Invalid file uploaded. Please try again.',
          descr: `Please make sure it's a CSV file and properly formatted`,
        }
      : null,
    fileList,
    accept: '.csv',
    name: CodeCreateFields.promoCodes,
  };
  const onChangeTT = (value: string[]) => form.setFieldsValue([{[CodeCreateFields.ticketType]: value}]);
  const onChangeManagedBy = (value: string[]) => form.setFieldsValue([{[CodeCreateFields.managedByUsers]: value}]);

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    console.log('Dropped files', e.dataTransfer.files);
  };

  const onFinish = async (values: any) => {
    if (fileTypeError) return;

    const emails = parseStringArray(values[CodeCreateFields.clientEmail]);
    const validEmail = validateEmailsArray(emails);
    if (!validEmail) return message.error({content: 'Please enter valid email'});
    setValues({...values, [CodeCreateFields.clientEmail]: JSON.stringify(emails)});
    setDelPopup(popupLvl.confirm);
  };
  const handleChangeForm = () => {
    setLoading(false);
    setEdited(true);
  };
  if (isLoading)
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    );
  const backText = isFromDetailed ? 'Back to Group View' : 'Back to Dashboard';
  return (
    <>
      {/*{createLoading && <CreateLoadingModal isEdit={!isCreate} />}*/}
      <PageWrapper>
        <TitleLine>
          <LinkBack onClick={openLeaveModal}>{backText}</LinkBack>
        </TitleLine>
        <CodeForm
          form={form}
          onChange={handleChangeForm}
          validateMessages={validateMessages}
          layout={'vertical'}
          onFinish={onFinish}>
          <GroupTitle>{title}</GroupTitle>
          <CodeFormItem
            label={<ItemLabel label="Client Name*" title={FormTips.clientName} />}
            rules={[{required: true, message: 'Client name is required'}]}
            name={[CodeCreateFields.clientName]}
            initialValue={initial?.clientName}>
            <Input placeholder="Enter client name" disabled={disabledClientName} />
          </CodeFormItem>
          <CodeFormItem
            label={'Contact Person*'}
            name={[CodeCreateFields.contactPerson]}
            initialValue={initial?.contactPerson}>
            <Input placeholder="Enter contact name" />
          </CodeFormItem>
          <CodeFormItem
            label={<ItemLabel label="Client Email*" title={FormTips.clientEmail} />}
            name={[CodeCreateFields.clientEmail]}
            rules={[{required: true, message: 'Client Email is required'}]}
            initialValue={initial?.clientEmailList}>
            <Input placeholder="Enter email" />
          </CodeFormItem>
          {/* {isCreate && ( */}
          <CodeFormItem
            label={'Group Type*'}
            name={[CodeCreateFields.groupType]}
            rules={[{required: true, message: 'Group Type is required'}]}
            initialValue={initial?.groupType}>
            <StyledSelect placeholder={'Select group type'} options={optionsGroup} disabled={disabled} />
          </CodeFormItem>
          {/* )} */}
          <CodeFormItem
            label={'Department*'}
            name={[CodeCreateFields.department]}
            rules={[{required: true, message: 'Department is required'}]}
            initialValue={isCreate ? user?.departments && user?.departments[0] : initial?.department}>
            <StyledSelect
              placeholder={'Select department'}
              options={options.departments}
              // eslint-disable-next-line
              //@ts-ignore
              onChange={setDepartment}
              disabled={disableDepartment}
            />
          </CodeFormItem>

          <CodeFormItem
            name={[CodeCreateFields.promoCodes]}
            rules={[{required: isCreate, message: 'Promo codes is required'}]}>
            <FileUpload onDrop={onDrop} onResultClick={resetError} {...props} />
          </CodeFormItem>
          <CodeFormItem
            label={'Assign to Person'}
            name={[CodeCreateFields.managedByUsers]}
            initialValue={isAssociate && isCreate ? [user?.email] : initial?.managedByUsers}>
            <SelectWithCheckbox
              onChange={onChangeManagedBy}
              // eslint-disable-next-line
              //@ts-ignore
              options={associates}
              initial={initial?.managedByUsers}
              disabled={disableAssign}
            />
          </CodeFormItem>

          <CodeFormItem
            label={<ItemLabel label="Ticket Type*" title={FormTips.ticketType} />}
            name={[CodeCreateFields.ticketType]}
            rules={[{required: isCreate, message: 'Ticket Type is required'}]}
            initialValue={initial?.ticketType?.split(',')}>
            <SelectWithCheckbox
              onChange={onChangeTT}
              options={ticketTypeOptions}
              initial={initial?.ticketType?.split(',')}
              disabled={disabled}
            />
          </CodeFormItem>

          <CodeFormItem
            label={'Seller Location*'}
            name={[CodeCreateFields.sellerId]}
            rules={[{required: true, message: 'Seller Location is required'}]}
            initialValue={initial?.sellerId}>
            <StyledSelect placeholder={'Select location'} options={options.sellers} disabled={disabledLocation} />
          </CodeFormItem>
          <CodeFormItem
            label={<ItemLabel label="Description*" title={FormTips.description} />}
            name={[CodeCreateFields.description]}
            rules={[{required: true, message: 'Description is required'}]}
            initialValue={initial?.description}>
            <TextArea placeholder={'Describe details'} rows={3} disabled={disabled} />
          </CodeFormItem>
          <ErrorLine>{error && <ErrorText>{error}</ErrorText>}</ErrorLine>
          {success?.status === 'completed' ? (
            <Success mode={mode} />
          ) : (
            <FormButton htmlType="submit" type={'primary'} disabled={loading}>
              {button}
            </FormButton>
          )}
        </CodeForm>
      </PageWrapper>
    </>
  );
};

export const Success: React.FC<{mode: FormMode}> = ({mode}) => {
  return (
    <SuccessLine>
      {mode === 'create' ? (
        <SuccessText>Promo Code Group successfully created</SuccessText>
      ) : (
        <SuccessText>Promo Code Group successfully updated</SuccessText>
      )}
    </SuccessLine>
  );
};
