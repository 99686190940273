import React, {useState} from 'react';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {
  MenuAction,
  ActionColumn,
  CodeInput,
  CodeColumn,
  CustomInfoButton,
  CodeColumnLine,
  CodeNames,
} from '../../Dashboard/styles';
import {Dropdown, Menu} from 'antd';
import {ButtonMore, ButtonTypes} from '../../../ui-kit/Button';
import {emailState, emailStateVal, ModalLvl} from './Table';
import {ViewCodeMap} from '../../../helpers/table';
import {codeItemT, CodeSendStatuses} from '../../../types/codes';
import {MAX_CODES_PER_GUEST, validateEmail} from '../../../helpers/codes';
import {ActionBtn} from '../../Settings/styles';
import {codeState, codeStateVal, useGuestChangeCodesT} from '../../../hooks/codes';
import {InputNumber} from '../../../ui-kit/Input';
import {CodeListWrapper, DropDownText, MaxCodesLabel, ViewMoreButton} from './styles';
import {CloseCircleOutlined} from '@ant-design/icons';

export type MenuMoreProps = {
  id?: string;
  focusedId?: string;
  setDelPopup?: (lvl: ModalLvl) => void;
  setFocusId?: (id?: string) => void;
  setEditing?: (b: boolean) => void;
};

export const MenuMore: React.FC<MenuMoreProps> = ({id, setDelPopup, setEditing, setFocusId}) => {
  const onEdit = () => {
    setFocusId?.(id);
    setEditing?.(true);
    setDelPopup?.(ModalLvl.closed);
  };
  const onDelete = () => {
    setFocusId?.(id);
    setEditing?.(false);
    setDelPopup?.(ModalLvl.confirm);
  };

  const ddActions: ItemType[] = [
    {key: 1, label: <MenuAction onClick={onEdit}>Edit</MenuAction>},
    {
      key: 2,
      label: (
        <MenuAction $danger onClick={onDelete}>
          Delete
        </MenuAction>
      ),
    },
  ];

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore />
    </Dropdown>
  );
};

export type CodeItemProps = {
  addRow?: boolean;
  edit?: boolean;
  code?: string;
  email?: string;
  guestName?: string;
  emailstate?: emailState;
  id?: string;
  editingId?: string;
  focusId?: string;
  setEmail?: (v: emailStateVal) => void;
  setName?: (v: emailStateVal) => void;
  setFocusId?: (id?: string) => void;
  unused?: codeItemT[];
  used?: codeItemT[];
  codesState: useGuestChangeCodesT;
  codeStatus?: CodeSendStatuses;
};

type InputItemProps = {
  editingEmail?: string;
  editEmail?: (value: any) => void;
  field: 'name' | 'email';
  editingId?: string;
  eCodeState?: codeState;
  eCodeSetState?: (val: codeStateVal) => void;
};

export const QuantityItem: React.FC<CodeItemProps> = ({
  used,
  addRow,
  editingId,
  id,
  unused,
  codesState,
  // codeStatus,
}) => {
  if (!id) return null;
  const isCurrentEditing = editingId === id;
  const totalUnused = Number(unused?.length || 0);
  const totalUsed = Number(used?.length || 0);
  const onChange = codesState?.changeCodes(id);
  const value = codesState.codes?.[id];
  const inc = () => onChange?.(Number(value || 0) + 1);
  const dec = () => {
    // const next = Number(value || 0) - 1;
    // if (next < totalUsed) return;
    onChange?.(Number(value || 0) - 1);
  };
  if (addRow)
    return (
      <CodeColumn>
        <InputNumber
          min={0}
          max={totalUnused}
          addonBefore={<button onClick={dec}>-</button>}
          addonAfter={
            <button onClick={inc} disabled={value === totalUnused}>
              +
            </button>
          }
          controls={false}
          onChange={onChange}
          value={value}
          disabled={!totalUnused}
        />
      </CodeColumn>
    );
  const incDisabled = value === totalUsed + totalUnused;
  if (isCurrentEditing)
    return (
      <CodeColumn>
        <InputNumber
          min={0}
          max={totalUsed + totalUnused}
          addonBefore={<button onClick={dec}>-</button>}
          addonAfter={
            <button onClick={inc} disabled={incDisabled}>
              +
            </button>
          }
          controls={false}
          onChange={onChange}
          value={value}
        />
      </CodeColumn>
    );
  return (
    <CodeColumnLine>
      <InputNumber
        min={0}
        addonBefore={<button disabled={true}>-</button>}
        addonAfter={<button disabled={true}>+</button>}
        controls={false}
        value={used?.length}
        disabled={true}
      />
    </CodeColumnLine>
  );
};

export const CodesItem: React.FC<CodeItemProps> = ({used}) => {
  const codes = used?.map((el) => el?.code);
  let codeNames = '';
  const codesMore = (used?.length || 0) > 2;
  if (codesMore) codeNames = codes?.slice(0, 2)?.join(', ') + '...';
  else codeNames = codes?.join(', ') || '';

  return (
    <CodeColumnLine>
      <CodeNames>{codeNames}</CodeNames>
      {codesMore && <MoreCodesDropDown codes={codes} />}
    </CodeColumnLine>
  );
};

const MoreCodesDropDown: React.FC<{codes: (string | undefined)[] | undefined}> = ({codes}) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };
  const codesList = (
    <CodeListWrapper>
      {codes?.map((code: string | undefined) => (
        <DropDownText key={code}>{code}</DropDownText>
      ))}
    </CodeListWrapper>
  );

  return (
    <ViewMoreButton>
      <Dropdown
        overlay={codesList}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={handleOpenChange}
        visible={open}>
        <DropDownText>View more</DropDownText>
      </Dropdown>
    </ViewMoreButton>
  );
};

export const InputItem: React.FC<CodeItemProps & InputItemProps> = ({
  email,
  id,
  setEmail,
  emailstate,
  setFocusId,
  field,
  guestName,
  editingId,
  eCodeState,
  eCodeSetState,
}) => {
  const isCurrentEditing = id && editingId === id;
  const onFocus = () => setFocusId?.(id);
  const onChangeName = (e: any) => setEmail?.({key: String(id), [field]: e?.target?.value});
  const onChangeEmail = (e: any) => setEmail?.({key: String(id), [field]: e?.target?.value.trim()});
  const onChange = field === 'email' ? onChangeEmail : onChangeName;
  const onCodeStateChangeName = (e: any) => eCodeSetState?.({key: field, value: e?.target?.value});
  const onCodeStateChangeEmail = (e: any) => eCodeSetState?.({key: field, value: e?.target?.value.trim()});
  const onCodeStateChange = field === 'email' ? onCodeStateChangeEmail : onCodeStateChangeName;

  const value = field === 'email' ? email : guestName;
  if (!id) return null;
  if (isCurrentEditing)
    return (
      <CodeColumn>
        <CodeInput
          placeholder={`Type ${field}...`}
          value={eCodeState?.[field] || ''}
          onChange={onCodeStateChange}
          onFocus={onFocus}
        />
      </CodeColumn>
    );

  return (
    <CodeColumn>
      {value || email ? (
        <div>{value}</div>
      ) : (
        <CodeInput
          placeholder={`Type ${field}...`}
          value={emailstate?.[id]?.[field] || ''}
          onChange={onChange}
          onFocus={onFocus}
        />
      )}
    </CodeColumn>
  );
};

export type ActionsItemProps = {
  edit?: boolean;
  id?: string;
  codeStatus?: CodeSendStatuses;
  onUpdateCode?: () => void;
  onCancelEdit?: () => void;
  onSendCode?: (id?: string) => void;
  toggleShare?: (id?: string, unshare?: boolean) => void;
  setFocusId?: (id?: string) => void;
  loading?: boolean;
  emailstate?: emailState;
  email?: string;
  setEditingId?: (id?: string) => void;
  editingId?: string;
  eCodeState?: codeState;
  updateTarget: (id?: string, eCodeState?: codeState, isAdd?: boolean) => Promise<boolean | undefined>;
  addRow?: boolean;
  codeCount?: number;
  unused?: codeItemT[];
  used?: codeItemT[];
  shareLoading?: string;
};

export const ActionsItem: React.FC<ActionsItemProps & MenuMoreProps> = ({
  codeStatus,
  onSendCode,
  id,
  setFocusId,
  loading,
  emailstate,
  email,
  eCodeState,
  setEditingId,
  editingId,
  updateTarget,
  addRow,
  codeCount,
  setDelPopup,
  unused,
  used,
}) => {
  const isCurrentEditing = id && editingId === id;
  const setEdit = () => setEditingId?.(id);
  const onCancelEdit = () => setEditingId?.('');
  const onSend = () => {
    setFocusId?.(id);
    onSendCode?.(id);
  };
  const canSave = (id && validateEmail(emailstate?.[id]?.email) && emailstate?.[id]?.name) || email;
  const canAdd = id && validateEmail(emailstate?.[id]?.email) && emailstate?.[id]?.name && codeCount;
  const rowUsed = used?.length || 0;
  const rowUnused = unused?.length || 0;
  const saveDisabled = !codeCount ? true : codeCount > rowUnused + rowUsed || codeCount > MAX_CODES_PER_GUEST;
  const onUpdateTarget = () => {
    if (id && !saveDisabled) updateTarget(id, addRow ? undefined : eCodeState, addRow);
  };
  const onDelete = () => {
    setFocusId?.(id);
    setDelPopup?.(ModalLvl.confirm);
  };
  if (addRow)
    return (
      <ActionColumn>
        <ActionBtn
          variant={ButtonTypes.secondary}
          onClick={onUpdateTarget}
          disabled={loading || !canAdd || saveDisabled}>
          Save
        </ActionBtn>
        {(codeCount || 0) > MAX_CODES_PER_GUEST && <MaxCodes />}
      </ActionColumn>
    );
  if (isCurrentEditing)
    return (
      <ActionColumn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={onCancelEdit} disabled={loading}>
          Cancel
        </ActionBtn>
        <ActionBtn
          variant={ButtonTypes.secondary}
          onClick={onUpdateTarget}
          disabled={loading || !eCodeState?.valid || saveDisabled}>
          Update
        </ActionBtn>
        {(codeCount || 0) > MAX_CODES_PER_GUEST && <MaxCodes />}
      </ActionColumn>
    );

  if (codeStatus === CodeSendStatuses.notsent && id && !email)
    return (
      <ActionColumn>
        <ActionBtn
          variant={ButtonTypes.secondary}
          onClick={onUpdateTarget}
          disabled={loading || !canSave || saveDisabled}>
          Save
        </ActionBtn>
        {(codeCount || 0) > MAX_CODES_PER_GUEST && <MaxCodes />}
      </ActionColumn>
    );
  if (codeStatus === CodeSendStatuses.notsent)
    return (
      <ActionColumn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={setEdit} disabled={loading}>
          Edit
        </ActionBtn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={onSend} disabled={loading}>
          Send
        </ActionBtn>
        <ActionBtn variant={ButtonTypes.tertiary} onClick={onDelete} disabled={loading}>
          Delete
        </ActionBtn>
      </ActionColumn>
    );

  const sendText = codeStatus === CodeSendStatuses.sent || codeStatus === CodeSendStatuses.queued ? 'Resend' : 'Send';
  return (
    <ActionColumn>
      {codeStatus === CodeSendStatuses.queued && (
        <CustomInfoButton variant={ViewCodeMap?.sent}>Queued</CustomInfoButton>
      )}
      {codeStatus === CodeSendStatuses.sent && (
        <CustomInfoButton variant={ViewCodeMap?.sent}>Code Sent</CustomInfoButton>
      )}
      <>{id && email && <MenuMorePromocode isEditing={false} onEdit={setEdit} onSend={onSend} sendText={sendText} />}</>
    </ActionColumn>
  );
};

export type MenuMorePromocodeProps = {
  isEditing: boolean;
  id?: string;
  onEdit?: () => void;
  onSend?: () => void;
  sendText: string;
};

export const MenuMorePromocode: React.FC<MenuMorePromocodeProps> = ({onEdit, onSend, sendText}) => {
  const ddActions: ItemType[] = [
    {key: 1, label: <MenuAction onClick={onEdit}>Edit</MenuAction>},
    {
      key: 2,
      label: <MenuAction onClick={onSend}>{sendText}</MenuAction>,
    },
  ];

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore />
    </Dropdown>
  );
};

export const CodeItem: React.FC<CodeItemProps> = ({code, addRow, editingId, id}) => {
  const isCurrentEditing = editingId === id;
  return (
    <CodeColumn>
      {addRow ? <CodeInput value={code} /> : <>{isCurrentEditing ? <CodeInput value={code} /> : <div>{code}</div>}</>}
      <div>{code}</div>
    </CodeColumn>
  );
};

const MaxCodes = () => (
  <MaxCodesLabel $valid={false} icon={<CloseCircleOutlined />}>
    No more than {MAX_CODES_PER_GUEST} codes per email
  </MaxCodesLabel>
);
