import React from 'react';
import {Form} from '../../components/CodeGroup/Form/Form';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useEditCodes, useGetCodeGroup} from '../../hooks/codes';
import {toInitialCodeGroupState} from '../../helpers/codes';
import {useViewer} from '../../hooks/auth';
import {getPermissions} from '../../helpers/roles';

export const EditCode = () => {
  const {id} = useParams();
  const formData = useEditCodes(id);
  const navigate = useNavigate();
  const {code, loading} = useGetCodeGroup(id);
  const values = toInitialCodeGroupState(code);
  const user = useViewer();
  const {isAssociate, isDeptManager, isSuperAdmin} = getPermissions();
  const location = useLocation();
  const isFromDetailed = location?.search?.includes('fromDetailed=true');
  return (
    <Form
      mode={'edit'}
      formData={formData}
      initial={values}
      isLoading={loading}
      navigate={navigate}
      codeGroup={code}
      user={user}
      isAssociate={isAssociate}
      isDeptManager={isDeptManager}
      isSuperAdmin={isSuperAdmin}
      isFromDetailed={isFromDetailed}
      groupId={id}
    />
  );
};
