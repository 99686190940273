import React from 'react';
import {ViewCodeGroup} from '../../components/CodeGroup/View';
import {useParams} from 'react-router-dom';
import {
  useAddCodes,
  useGetCodeGroup,
  useGetListPromoCodes,
  useGetSellers,
  usePromoCodesActions,
  useResetGroup,
  useSendCode,
  useSendGuestEmail,
} from '../../hooks/codes';
import {GroupFooterContainer, InternalGroupFooterContainer} from '../Layout/Footer';
import {useSetEmails, useUpdateTarget, useUploadEmails} from '../../hooks/emails';
import {getPermissions} from '../../helpers/roles';
import {useViewer} from '../../hooks/auth';
import {useHelpModal} from '../../hooks/onboarding';
import {Onboarding} from './Onboarding';
// import {useUploadEmails} from '../../hooks/emails';

export const ViewCode = () => {
  const {id} = useParams();
  const {code, loading: codeLoading, refetch: refetchGroup} = useGetCodeGroup(id);
  const {
    tableData,
    refetch,
    loading: listLoading,
    notSentEmails,
    usedCodes,
    searchPromoCodes,
    onSetTableView,
    tableView,
    downloadCSV,
  } = useGetListPromoCodes(id);

  const fullRefetch = () => {
    refetchGroup();
    refetch();
  };
  const {onDelete, onDeletePromoCode, onUpdate} = usePromoCodesActions(id, refetch);
  const {addCodes} = useAddCodes(id, fullRefetch);
  const {sendCode} = useSendCode(id, fullRefetch);
  const {sendGuest} = useSendGuestEmail(id, fullRefetch);
  const emailsData = useSetEmails();
  const {updateTarget} = useUpdateTarget({
    groupId: id,
    onSuccess: refetch,
    refetchGroup,
    emails: emailsData.emails,
    usedCodes,
  });
  const {isAssociate} = getPermissions();
  const canAddEdit = !isAssociate;
  const {sellers} = useGetSellers();
  const user = useViewer();
  const modals = useHelpModal();
  const {upload: uploadEmails} = useUploadEmails(id ? [id] : [], fullRefetch);
  const handleReset = useResetGroup(code, fullRefetch);
  return (
    <>
      <Onboarding />
      <ViewCodeGroup
        onUploadEmails={uploadEmails}
        code={code}
        codeLoading={codeLoading}
        tableData={tableData}
        onDelete={onDelete}
        onUpdate={onUpdate}
        addCodes={addCodes}
        onDeletePromoCode={onDeletePromoCode}
        onSendCode={sendCode}
        onSendGuest={sendGuest}
        listLoading={listLoading}
        emailsData={emailsData}
        // uploadEmails={upload}
        fullRefetch={fullRefetch}
        id={id}
        canAddEdit={canAddEdit}
        sellers={sellers}
        user={user}
        updateTarget={updateTarget}
        searchPromoCodes={searchPromoCodes}
        setTableView={onSetTableView}
        tableView={tableView}
        modals={modals}
        downloadCSV={downloadCSV}
        handleReset={handleReset}
      />
      {code?.id ? (
        code?.groupType === 'external' ? (
          <GroupFooterContainer onSuccess={refetchGroup} />
        ) : (
          <InternalGroupFooterContainer onSuccess={fullRefetch} code={code} sendCount={notSentEmails} />
        )
      ) : (
        <></>
      )}
    </>
  );
};
