import React, {useEffect, useState} from 'react';
import {Table, TableTitle} from '../styles';
import {TableWrapper} from '../../../ui-kit/Table';
import {RoleConfigsTableColumnsTitles, RoleConfigsTableFields, TableColumnT} from '../../../helpers/table';
import {RoleItemProps} from '../ManagementTable/TableItems';
import {ActionsItem, ActionsItemProps, ManageDistrProps, ManagePermissionItem, RoleNameItem} from './TableItems';
import {ModalLvl} from '../../CodeGroup/View/Table';
import {Modal} from 'antd';
import {ModalDescr} from '../../Dashboard/styles';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {RoleTableItemT} from '../../../types/settings';
import {RoleFormT} from '../../../types/formData';
import {CreateRoleField, PermissionT} from '../../../queries/types/settings';
import {toUnderScore} from '../../../helpers/settings';

type RolesTablePropsT = {
  data: RoleTableItemT[];
  onCreate: (v: RoleFormT) => void;
  onDelete: (id: string) => void;
  permissions: PermissionT[];
};

export const RolesTable: React.FC<RolesTablePropsT> = ({data, onCreate, onDelete, permissions}) => {
  const [addName, setAddName] = useState<string | undefined>('');

  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [isEditing, setIsEditing] = useState(false);
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [addModalLvl, setAddModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (b: boolean) => setIsEditing(b);

  // const tipTitles = {
  //   view: 'Users with this permission can only view data in the Portals',
  //   manageGroup: (
  //     <>
  //       <div>On Promo Code Portal, users with this permission can share access with clients</div>
  //       <br />
  //       <div>
  //         On Ticket Distribution Portal, users with this permission can create, manage ticket groups and share access
  //         with clients.
  //       </div>
  //     </>
  //   ),
  //   manageDistr: `Users with this permission can send codes or tickets directly to the guests' emails.`,
  // };
  const handleEditName = (e: any) => setAddName(e?.target.value);
  const onAdd = async () => {
    await onCreate({[CreateRoleField.name]: addName});
    setAddModalLvl(ModalLvl.success);
    setAddName('');
  };
  const PermissionColumns = permissions.map((el, i) => ({
    key: toUnderScore(el.name) || String(i),
    dataIndex: toUnderScore(el.name) || String(i),
    title: el.name,
    render: (props: ManageDistrProps) => <ManagePermissionItem {...props} />,
  }));
  const roleColumns: TableColumnT[] = [
    {
      key: RoleConfigsTableFields.name,
      dataIndex: RoleConfigsTableFields.name,
      title: RoleConfigsTableColumnsTitles.role,
      render: (props: RoleItemProps) => <RoleNameItem {...props} addName={addName} onChange={handleEditName} />,
    },
    // {
    //   key: RoleConfigsTableFields.view,
    //   dataIndex: RoleConfigsTableFields.view,
    //   title: <TitleWithTooltip label={RoleConfigsTableColumnsTitles.view} title={tipTitles.view} />,
    //   render: (props: ViewItemProps) => <ViewItem {...props} />,
    // },
    ...PermissionColumns,
    {
      key: RoleConfigsTableFields.actions,
      dataIndex: RoleConfigsTableFields.actions,
      title: RoleConfigsTableColumnsTitles.action,
      render: (props: ActionsItemProps) => (
        <ActionsItem
          {...props}
          onAdd={onAdd}
          setDelPopup={setDelPopup}
          setFocusId={setFocusId}
          setEditing={setEditing}
          focusedId={focusedId}
          isEditing={isEditing}
        />
      ),
    },
  ];
  useEffect(() => {
    const roleName = data?.find((el) => el.id?.id === focusedId)?.name?.name || '';

    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `You are request to remove ${roleName}?`,
        content: <ModalDescr>Upon confirming, the role will be removed from the system.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <QuestionCircleOutlined />,
        onOk: () => {
          focusedId && onDelete?.(focusedId);
          setDelPopup(ModalLvl.success);
        },
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `${roleName} is removed!`,
        content: <ModalDescr>The role has been removed from the system.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          console.log('delete', focusedId);
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  useEffect(() => {
    if (addModalLvl === ModalLvl.success) {
      Modal.success({
        title: `Success!`,
        content: <ModalDescr>The role has been added to the system.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          setAddModalLvl(ModalLvl.closed);
        },
      });
    }
  }, [addModalLvl]);
  // const roleData: ConfigTableItemT[] = [
  //   {
  //     role: {addRow: true},
  //     view: {addRow: true},
  //     manageGroup: {addRow: true},
  //     manageDistr: {addRow: true},
  //     actions: {addRow: true},
  //   },
  //   {
  //     id: roleConfigItem1,
  //     role: roleConfigItem1,
  //     view: roleConfigItem1,
  //     manageGroup: roleConfigItem1,
  //     manageDistr: roleConfigItem1,
  //     actions: {id: roleConfigItem1.id},
  //   },
  // ];
  return (
    <>
      <TableTitle level={3}>Role</TableTitle>
      <TableWrapper>
        <Table columns={roleColumns} dataSource={data} pagination={false} />
      </TableWrapper>
    </>
  );
};
