import React from 'react';
import {ButtonTypes} from '../ui-kit/Button';
import {CodeAccessTypes, CodeActionTypes} from '../types/codes';

export type TableColumnT = {
  title: string | React.ReactNode;
  dataIndex: string;
  key: string;
  render?: (a: any) => React.ReactNode;
};

export const createColumns = (columns: {key: string; label: string}[]): TableColumnT[] => {
  return columns?.map((el) => ({title: el.label, dataIndex: el.key, key: el.key}));
};

export enum DashboardTableFields {
  id = 'id',
  clientName = 'clientName',
  ticketType = 'ticketType',
  department = 'department',
  contactPerson = 'contactPerson',
  createdOn = 'createdOn',
  totalCodes = 'totalCodes',
  codesSent = 'codesSent',
  status = 'status',
  actions = 'actions',
}

export const DashboardTableColumnsTitles = {
  id: 'ID',
  clientName: 'Client Name',
  ticketType: 'Ticket Type',
  department: 'Department',
  contactPerson: 'Contact Person',
  createdOn: 'Created on',
  totalCodes: 'Total Codes',
  codesSent: 'Codes Sent',
  status: 'Status',
  actions: '',
};

export enum GroupDetailTableFields {
  code = 'code',
  quantity = 'quantity',
  email = 'email',
  guestName = 'guestName',
  actions = 'actions',
  status = 'status',
}

export const GroupDetailTableColumnsTitles = {
  quantity: 'Quantity',
  code: 'Promo Codes',
  actions: 'Action',
  status: '',
};

export const DashboardCodeMap = {
  [CodeAccessTypes.sent]: ButtonTypes.secondary,
  [CodeAccessTypes.notsent]: ButtonTypes.primary,
  [CodeAccessTypes.Creating]: ButtonTypes.tertiary,
};

export const StatusTitleMap = {
  [CodeAccessTypes.sent]: 'Shared',
  [CodeAccessTypes.notsent]: 'Pending',
  [CodeAccessTypes.Creating]: 'Creating',
};

export const ViewCodeMap = {
  [CodeActionTypes.sent]: ButtonTypes.tertiary,
  [CodeActionTypes.applied]: ButtonTypes.quaternary,
  [CodeActionTypes.shared]: ButtonTypes.senary,
  [CodeActionTypes.assigned]: ButtonTypes.senary,
};

export enum ManagementTableFields {
  fullName = 'fullName',
  email = 'email',
  role = 'role',
  department = 'department',
  access = 'access',
  actions = 'actions',
}

export const ManagementTableColumnsTitles = {
  fullName: 'Full Name',
  email: 'Email Address',
  role: 'Role',
  department: 'Department',
  access: 'Portal Access',
  actions: 'Actions',
};

export enum RoleConfigsTableFields {
  name = 'name',
  view = 'view',
  manageGroup = 'manageGroup',
  manageDistr = 'manageDistr',
  actions = 'actions',
}

export const RoleConfigsTableColumnsTitles = {
  role: 'Roles',
  view: 'View-only',
  manageGroup: 'Manage Group',
  manageDistr: 'Manage Distribution',
  action: 'Action',
};

export enum ConfigsTableFields {
  types = 'types',
  actions = 'actions',
}

export const ConfigsTableColumnsTitles = {
  types: 'Ticket types',
  action: 'Actions',
};

//------- DEPARTMENT ---------
export enum DepartmentTableFields {
  name = 'name',
  actions = 'actions',
}

export const DepartmentTableColumnsTitles = {
  types: 'Types',
  action: 'Actions',
};

//------- PERMISSIONS ---------
export enum PermissionTableFields {
  name = 'name',
  description = 'description',
  actions = 'actions',
}

export const PermissionTableColumnsTitles = {
  name: 'Name',
  description: 'Description',
  action: 'Actions',
};
