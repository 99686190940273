import {BaseObjT} from '../../types/helpers';

export enum CreateUserFields {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  roleId = 'roleId',
  departmentId = 'departmentId',
}

export type UserT = BaseObjT & {
  email: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  active?: boolean;
  userType?: string;
  roleId?: number;
  departments?: string[];
  portals?: string[];
  timezone?: string;
};

export type getUsersResT = UserT[];

export type UserBody = {
  [CreateUserFields.email]: string;
  [CreateUserFields.firstName]: string;
  [CreateUserFields.lastName]: string;
  [CreateUserFields.roleId]: number;
  [CreateUserFields.departmentId]: number;
};

export type inviteUserReq = {
  body: UserBody;
};

export type getUserReq = {
  userId: string;
};

export type updateUserReq = {
  userId: string;
  body: UserBody;
};

export type deleteUserReq = {
  userId: string;
};
