import React from 'react';
import {Navigate} from 'react-router-dom';
import {route} from '../constants/routes';
import {WithChildren} from '../types/helpers';
import {LOGIN_EXTERNAL_LINK} from '../constants/env';
import {useViewer} from '../hooks/auth';
import {resolveRoute} from '../helpers/Route';

export const PrivateRoute: React.FC<WithChildren & {path?: string}> = ({children, path}) => {
  const isAuth = useViewer();
  const redirect = resolveRoute(path);

  if (redirect) return <Navigate to={route.dashboard.path} replace />;
  if (!isAuth && LOGIN_EXTERNAL_LINK) return <Navigate to={route.redirectToLogin.path} replace />;

  return <>{children}</>;
};
