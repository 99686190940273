import React from 'react';
import {
  HeaderContainer,
  HeaderLeftSide,
  HeaderRightSide,
  HeaderWrapper,
  HeaderLogoMedium,
  SettingsButton,
  HeaderRightText,
  HeaderButtons,
  LogoutButton,
  HeaderLeftText,
  HeaderDropDown,
  HeaderActiveMenuItem,
  HeaderLink,
} from './styles';
import Logo from './assets/ENC_Logo.png';
import {DownOutlined, SettingOutlined} from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';
import {ItemType} from 'antd/es/menu/hooks/useItems';

export type HeaderPropsT = {
  rightText?: string;
  leftText?: string;
  dropDownLinks?: ItemType[];
  onSettingsClick?: () => void;
  onLogout?: () => void;
  logoutText?: string;
  logoLink?: string;
};

export const Header = ({
  onSettingsClick,
  rightText,
  logoutText,
  onLogout,
  leftText,
  dropDownLinks,
  logoLink,
}: HeaderPropsT) => {
  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLeft leftText={leftText} dropDownLinks={dropDownLinks} />
        <HeaderLink to={logoLink || '/'}>
          <HeaderLogoMedium src={Logo} />
        </HeaderLink>
        <HeaderRightSide>
          {rightText && <HeaderRightText changeMargin={onSettingsClick ? true : false}>{rightText}</HeaderRightText>}
          <HeaderButtons>
            {onSettingsClick && <SettingsButton onClick={onSettingsClick} icon={<SettingOutlined />} />}
            <LogoutButton onClick={onLogout}>{logoutText || 'Log Out'}</LogoutButton>
          </HeaderButtons>
        </HeaderRightSide>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

type HeaderLeftPropsT = {
  leftText?: string;
  dropDownLinks?: ItemType[];
};

const HeaderLeft: React.FC<HeaderLeftPropsT> = ({leftText, dropDownLinks}) => {
  return (
    <HeaderLeftSide>
      {dropDownLinks?.length ? (
        <HeaderDropDown>
          <Dropdown overlay={<Menu items={dropDownLinks} />} trigger={['click']}>
            <HeaderActiveMenuItem>
              <HeaderLeftText>{leftText}</HeaderLeftText>
              <DownOutlined />
            </HeaderActiveMenuItem>
          </Dropdown>
        </HeaderDropDown>
      ) : (
        leftText && <HeaderLeftText>{leftText}</HeaderLeftText>
      )}
    </HeaderLeftSide>
  );
};
