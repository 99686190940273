import request from 'superagent';
import {codeGroupBody} from '../queries/types/codes';
import {CodeCreateFields} from '../types/codes';
import {CreateUserFields, UserBody} from '../queries/types/user';
import {
  CreateDepartmentField,
  CreatePermissionField,
  CreateRoleField,
  DepartmentBody,
  PermissionBody,
  RoleBody,
} from '../queries/types/settings';

export const wrapUpdateCodesGroup = (query: request.SuperAgentRequest, body: codeGroupBody) => {
  if (body[CodeCreateFields.clientName]) query.field(CodeCreateFields.clientName, body[CodeCreateFields.clientName]);
  if (body[CodeCreateFields.contactPerson])
    query.field(CodeCreateFields.contactPerson, body[CodeCreateFields.contactPerson]);
  if (body[CodeCreateFields.clientEmail]) query.field(CodeCreateFields.clientEmail, body[CodeCreateFields.clientEmail]);
  if (body[CodeCreateFields.department]) query.field(CodeCreateFields.department, body[CodeCreateFields.department]);
  if (body[CodeCreateFields.promoCodes]) query.field(CodeCreateFields.promoCodes, body[CodeCreateFields.promoCodes]);
  if (body[CodeCreateFields.ticketType]) query.field(CodeCreateFields.ticketType, body[CodeCreateFields.ticketType]);
  if (body[CodeCreateFields.description]) query.field(CodeCreateFields.description, body[CodeCreateFields.description]);
  if (body[CodeCreateFields.managedByUsers])
    query.field(CodeCreateFields.managedByUsers, JSON.stringify(body[CodeCreateFields.managedByUsers]));
  if (body[CodeCreateFields.sellerId]) query.field(CodeCreateFields.sellerId, body[CodeCreateFields.sellerId]);
  if (body[CodeCreateFields.groupType]) query.field(CodeCreateFields.groupType, body[CodeCreateFields.groupType]);

  return query;
};

export const wrapCreateUser = (query: request.SuperAgentRequest, body: UserBody) => {
  if (body[CreateUserFields.email]) query.field(CreateUserFields.email, body[CreateUserFields.email]);
  if (body[CreateUserFields.firstName]) query.field(CreateUserFields.firstName, body[CreateUserFields.firstName]);
  if (body[CreateUserFields.lastName]) query.field(CreateUserFields.lastName, body[CreateUserFields.lastName]);
  if (body[CreateUserFields.roleId]) query.field(CreateUserFields.roleId, body[CreateUserFields.roleId]);
  if (body[CreateUserFields.departmentId])
    query.field(CreateUserFields.departmentId, body[CreateUserFields.departmentId]);

  return query;
};

export const wrapCreateDepartment = (query: request.SuperAgentRequest, body: DepartmentBody) => {
  if (body[CreateDepartmentField.name]) query.field(CreateDepartmentField.name, body[CreateDepartmentField.name]);
  if (body[CreateDepartmentField.description])
    query.field(CreateDepartmentField.description, body[CreateDepartmentField.description]);

  return query;
};

export const wrapCreatePermission = (query: request.SuperAgentRequest, body: PermissionBody) => {
  if (body[CreatePermissionField.name]) query.field(CreatePermissionField.name, body[CreatePermissionField.name]);
  if (body[CreatePermissionField.description])
    query.field(CreatePermissionField.description, body[CreatePermissionField.description]);

  return query;
};

export const wrapCreateRole = (query: request.SuperAgentRequest, body: RoleBody) => {
  if (body[CreateRoleField.name]) query.field(CreateRoleField.name, body[CreateRoleField.name]);

  return query;
};

const getFormData = (object: any) =>
  Object.entries(object).reduce((fd, [key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((v) => fd.append(`${key}`, v));
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      fd.append(key, val);
    }
    return fd;
  }, new FormData());

export const wrapCreateCodesGroup = (body: codeGroupBody) => {
  const data = {
    [CodeCreateFields.clientName]: body[CodeCreateFields.clientName],
    [CodeCreateFields.contactPerson]: body[CodeCreateFields.contactPerson],
    [CodeCreateFields.clientEmail]: body[CodeCreateFields.clientEmail],
    [CodeCreateFields.department]: body[CodeCreateFields.department],
    [CodeCreateFields.promoCodes]: body[CodeCreateFields.promoCodes],
    [CodeCreateFields.ticketType]: body[CodeCreateFields.ticketType],
    [CodeCreateFields.description]: body[CodeCreateFields.description],
    [CodeCreateFields.groupType]: body[CodeCreateFields.groupType],
    [CodeCreateFields.managedByUsers]: JSON.stringify(body[CodeCreateFields.managedByUsers]),
    [CodeCreateFields.sellerId]: body[CodeCreateFields.sellerId],
  };
  const fd = getFormData(data);
  return fd;
};
