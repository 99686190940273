import styled, {css} from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {StaticCard} from '../../ui-kit/StaticCard';
import {Button, InfoButton, LinkButton} from '../../ui-kit/Button';
import {PrimaryTable} from '../../ui-kit/Table';
import {Typography} from 'antd';
import Input, {InputNumber} from '../../ui-kit/Input';
const {Text} = Typography;

const buttonStyle = css`
  border: none;
  background-color: transparent;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  padding: 0;
  :hover {
    background-color: transparent;
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 141px 32px 141px;
  ${Media.down.xxl} {
    padding: 54px 90px 32px 90px;
  }
`;

export const DashboardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const DashboardCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const DashboardCard = styled(StaticCard)`
  margin: 4px 24px 4px 0;
  min-width: 145px;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const DashboardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-right: -40px;
`;

export const CreateButton = styled(LinkButton).attrs({
  type: 'primary',
})`
  margin-right: 22px;
`;

export const CodesTable = styled(PrimaryTable)``;

export const MenuAction = styled.div<{$danger?: boolean}>`
  color: ${({theme, $danger}) => (!$danger ? theme.palette.text.primary : theme.palette.common.red)};
`;

export const ModalDescr = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  display: block;
`;

export const AddButton = styled(Button).attrs({
  type: 'primary',
})`
  margin-left: 5px;
`;

export const ActionColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  margin-left: auto;
  width: 100px;
`;

export const CodeColumn = styled.div``;
export const CodeColumnLine = styled.div`
  display: flex;
  align-items: center;
`;
export const CodeNames = styled.div`
  font-size: 11px;
  margin-left: 8px;
`;

export const CodeCountInput = styled(InputNumber)`
  max-width: 200px;
  height: 32px;
`;

export const CodeInput = styled(Input)`
  max-width: 200px;
  height: 32px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CreatingLabel = styled(InfoButton)`
  background-color: ${({theme}) => theme.palette.background.darkGray};
`;

export const SearchWrapper = styled.div`
  padding: 15px 19px;
  background-color: ${({theme}) => theme.palette.common.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  display: flex;
`;

export const SearchInput = styled(Input)`
  border-radius: 4px;
  min-width: 300px;
  height: 36px;
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
`;

export const EnterButton = styled(Button)`
  width: 129px;
  height: 36px;
  border-radius: 40px;
  margin-left: 8px;
`;

export const SearchResultWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
`;

export const ResultLabel = styled.span`
  line-height: 20px;
  color: ${({theme}) => theme.palette.common.black};
`;

export const ClearButton = styled.button`
  border: none;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
`;

export const SearchBtn = styled(Button).attrs({
  type: 'text',
})`
  ${buttonStyle}
  span > svg {
    color: ${({theme}) => theme.palette.common.black};
    font-size: 20px;
  }
  :active,
  :hover,
  :focus {
    background-color: transparent;
  }
`;

export const CustomInfoButton = styled(InfoButton)`
  margin-right: 6px;
`;

export const PaginationWrapper = styled.div`
  margin: 40px 0px;
  display: flex;
  justify-content: flex-end;
`;
