import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {route} from './constants/routes';
import {RedirectToLogin} from './containers/RedirectToLogin';
import {Dashboard} from './containers/Dashboard';
import {PrivateRoute} from './containers/PrivateRoute';
import {EditCode} from './containers/CodeGroup/Edit';
import {CreateCode} from './containers/CodeGroup/Create';
import {ViewCode} from './containers/CodeGroup/View';
import {SettingsPage} from './containers/Settings';

const PublicRoutes = [
  <Route key="redirect-to-login-page" path={route.redirectToLogin.path} element={<RedirectToLogin />} />,
];

const PrivateRoutes = [
  <Route
    key="dashboard"
    path={route.dashboard.path}
    element={
      <PrivateRoute path={route.dashboard.path}>
        <Dashboard />
      </PrivateRoute>
    }
  />,
  <Route
    key="create-code-group"
    path={route.createCodeGroup.path}
    element={
      <PrivateRoute path={route.createCodeGroup.path}>
        <CreateCode />
      </PrivateRoute>
    }
  />,
  <Route
    key="edit-code-group"
    path={route.editCodeGroup.path}
    element={
      <PrivateRoute path={route.editCodeGroup.path}>
        <EditCode />
      </PrivateRoute>
    }
  />,
  <Route
    key="view-code-group"
    path={route.viewCodeGroup.path}
    element={
      <PrivateRoute path={route.viewCodeGroup.path}>
        <ViewCode />
      </PrivateRoute>
    }
  />,
  <Route
    key="settings"
    path={route.settings.path}
    element={
      <PrivateRoute path={route.settings.path}>
        <SettingsPage />
      </PrivateRoute>
    }
  />,
];

const RoutesSwitch: React.FC = () => {
  return (
    <Routes>
      {PrivateRoutes}
      {PublicRoutes}
    </Routes>
  );
};

export default RoutesSwitch;
