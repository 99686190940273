import {SelectOptionT} from '../types/helpers';
import {
  AccessPermissions,
  CreatingUserStateT,
  DepartmentsList,
  DepTableItemT,
  ManageTableItemT,
  PermissionTableItemT,
  Roles,
  RolesLabels,
  RoleTableItemT,
} from '../types/settings';
import {
  CreateDepartmentField,
  CreatePermissionField,
  CreateRoleField,
  CreateUserBodyT,
  DepartmentBody,
  DepartmentT,
  PermissionBody,
  PermissionT,
  RoleBody,
  RoleT,
} from '../queries/types/settings';
import {DepartmentFormT, PermissionFormT, RoleFormT} from '../types/formData';
import {User} from '../types/auth';
import {SelectOptionsT} from '../ui-kit/Select';

export const getDepartmentOptions = (): SelectOptionT[] => {
  return [
    {label: 'All Departments', value: 'all'},
    {
      label: DepartmentsList.sales,
      value: DepartmentsList.sales,
    },
    {
      label: DepartmentsList.labs,
      value: DepartmentsList.labs,
    },

    {
      label: DepartmentsList.marketing,
      value: DepartmentsList.marketing,
    },

    {
      label: DepartmentsList.village,
      value: DepartmentsList.village,
    },

    {
      label: DepartmentsList.executives,
      value: DepartmentsList.executives,
    },
  ];
};
export const getRoleOptions = (): SelectOptionT[] => {
  return [
    {
      label: RolesLabels[Roles.admin],
      value: Roles.admin,
    },
    {
      label: RolesLabels[Roles.manager],
      value: Roles.manager,
    },
    {
      label: RolesLabels[Roles.associate],
      value: Roles.associate,
    },
  ];
};

export const getAccessOptions = (): SelectOptionsT[] => {
  return [
    // {label: 'All', value: 'all'},
    {
      label: AccessPermissions.promo,
      value: AccessPermissions.promo,
    },
    {
      label: AccessPermissions.ticket,
      value: AccessPermissions.ticket,
    },
    {
      label: AccessPermissions.notifications,
      value: AccessPermissions.notifications,
    },
  ];
};

export const departmentsToTableData = (data: DepartmentT[]): DepTableItemT[] => {
  const tdata: DepTableItemT[] = data.map((el) => ({
    id: {id: el.id},
    name: {name: el.name},
    description: {description: el.description},
    actions: {id: el.id, addRow: false},
  }));
  return [
    // {
    //   name: {addRow: true},
    //   actions: {addRow: true},
    // },
    ...tdata,
  ];
};

export const departmentToCreateData = (values: DepartmentFormT): DepartmentBody => {
  return {
    [CreateDepartmentField.name]: values?.name || '',
    [CreateDepartmentField.description]: values?.description || '',
  };
};

export const permissionsToTableData = (data: PermissionT[]): PermissionTableItemT[] => {
  const tdata: DepTableItemT[] = data.map((el) => ({
    id: {id: el.id},
    name: {name: el.name},
    description: {description: el.description},
    actions: {id: el.id, addRow: false},
  }));
  return [
    // {
    //   name: {addRow: true},
    //   description: {addRow: true},
    //   actions: {addRow: true},
    // },
    ...tdata,
  ];
};

export const permissionToCreateData = (values: PermissionFormT): PermissionBody => {
  return {
    [CreatePermissionField.name]: toUnderScore(values?.name) || '',
    [CreatePermissionField.description]: values?.description || '',
  };
};

export const toUnderScore = (str?: string) => {
  return str?.replace(/\s+/g, ' ').trim().replaceAll(' ', '_').toUpperCase();
};

export const toCommonText = (str?: string) => {
  return str?.trim().replaceAll('_', ' ').toUpperCase();
};

export const roleToCreateData = (values: RoleFormT): RoleBody => {
  return {
    [CreateRoleField.name]: values?.name || '',
  };
};

export const rolesToTableData = (data: RoleT[]): RoleTableItemT[] => {
  const tdata: RoleTableItemT[] = data.map((el) => ({
    id: {id: el.id},
    name: {name: el.name},
    actions: {id: el.id, addRow: false},
  }));
  return [
    // {
    //   name: {addRow: true},
    //   actions: {addRow: true},
    // },
    ...tdata,
  ];
};

export const usersToTableState = (values: User[]): ManageTableItemT[] => {
  const tdata = values
    .sort((a, b) => {
      if (Number(a?.id) > Number(b?.id)) return -1;
      if (Number(a?.id) < Number(b?.id)) return 1;
      return 0;
    })
    .map((el) => ({
      id: {id: String(el?.id)},
      fullName: {fullName: `${el?.firstName} ${el?.lastName}`, id: String(el?.id)},
      email: {email: el?.email, id: String(el?.id)},
      role: {role: el?.roles?.[0] || '', id: String(el?.id), roleId: el?.roles?.[0]},
      department: {
        department: el?.departments?.[0] || '',
        id: String(el?.id),
        departmentId: String(el?.departments?.[0]),
      },
      access: {access: el?.portals, id: String(el?.id)},
      actions: {id: String(el?.id)},
    }));

  return [
    {
      id: {addRow: true},
      fullName: {addRow: true},
      email: {addRow: true},
      role: {addRow: true},
      department: {addRow: true},
      access: {addRow: true},
      actions: {addRow: true},
    },
    ...tdata,
  ];
};

export const departmentToOptions = (departments: DepartmentT[]): SelectOptionT[] => {
  return departments.map((el) => ({label: el?.name, value: String(el?.name)}));
};
export const rolesToOptions = (departments: RoleT[]): SelectOptionT[] => {
  return departments.map((el) => ({label: el?.name, value: String(el?.name)}));
};

export const userToCreateBody = (values: CreatingUserStateT): CreateUserBodyT | null => {
  if (
    !values?.name ||
    !values?.email ||
    !String(values?.roleId) ||
    !String(values?.departmentId) ||
    !values?.access?.length
  )
    return null;
  return {
    email: values?.email || '',
    departments: values?.departmentId ? [values?.departmentId] : [],
    firstName: values?.name?.trim()?.split(' ')?.[0] || '',
    lastName: values?.name?.trim()?.split(' ')?.[1] || '',
    roles: values?.roleId ? [values?.roleId] : [],
    portals: values?.access,
  };
};
