import React from 'react';
import {RolesConfiguration} from './Role';
import {DepartmentsConfiguration} from './Departments';
import {TicketConfiguration} from './Ticket';
import {ConfigTypeT} from '../../types/settings';
import {PermissionsConfiguration} from './Permissions';

export type ConfigurationsProps = {
  configType: ConfigTypeT;
};

export const Configurations: React.FC<ConfigurationsProps> = ({configType}) => {
  if (configType === 'role') return <RolesConfiguration />;
  if (configType === 'department') return <DepartmentsConfiguration />;
  if (configType === 'ticket') return <TicketConfiguration />;
  if (configType === 'permission') return <PermissionsConfiguration />;
  return <></>;
};
