import {PromoCodeT} from '../queries/types/codes';

export type FormMode = 'create' | 'edit';

export enum CodeStatuses {
  code_sent = 'code_sent',
  code_used = 'code_used',
  code_received = 'code_received',
}

export enum CodeStatusTypes {
  all = 'all',
  sent = 'sent',
  pending = 'pending',
  used = 'used',
  received = 'received',
}

export enum CodeAccessTypes {
  sent = 'sent',
  notsent = 'notsent',
  Creating = 'Creating',
}

export enum CodeActionTypes {
  sent = 'sent',
  applied = 'applied',
  shared = 'shared',
  assigned = 'assigned',
}

export type GroupDetailTableDataT = {
  key: string | number;
  id: string;
  code: {addRow?: boolean; code?: string; id?: string; unused?: codeItemT[]; email?: string | null; used?: codeItemT[]};
  guestName: {addRow?: boolean; guestName?: string; id?: string};
  email: {edit?: boolean; email?: string | null; id?: string};
  actions: {edit?: boolean; codeStatus?: CodeSendStatuses; id?: string};
};

export type CodeCreateFormValues = {
  clientName?: string;
  contactPerson?: string;
  clientEmailList?: string;
  groupType?: string;
  department?: string;
  promoCodes: string | File;
  ticketType?: string[];
  description?: string;
  managedByUsers?: string[];
  sellerId?: string;
};

export enum CodeCreateFields {
  clientName = 'clientName',
  contactPerson = 'contactPerson',
  clientEmail = 'clientEmailList',
  groupType = 'groupType',
  department = 'department',
  promoCodes = 'promoCodes',
  ticketType = 'ticketType',
  description = 'description',
  managedByUsers = 'managedByUsers',
  sellerId = 'sellerId',
}

export enum CodeSendStatuses {
  sent = 'sent',
  queued = 'queued',
  notsent = 'notsent',
  externalShare = 'externalShare',
}

export type OnUpdateCodeT =
  | ((props: {
      codeId?: string | undefined;
      email?: string | undefined;
      guestName?: string | undefined;
    }) => Promise<boolean>)
  | undefined;

export type EmailsMapItemT = {
  email: string;
  guestName?: string;
  codes?: codeItemT[];
  status?: string;
};
export type codeItemT = {
  id?: number;
  code?: string;
  status?: string;
};

export type UsedCodesT = {id: string; used: codeItemT[] | undefined}[];

export type GuestT = {
  clientEmail: string;
  clientName: string;
  codeGroupGuests: CodeGroupGuestT[];
  contactPerson: string;
  createdAt: string;
  department: string;
  description: string;
  groupType: string;
  guests: SubGuestT[];
  id: number;
  managedByUsers: string[];
  outboundStatus: string;
  sellerId: string;
  ticketType: string;
  updatedAt: string;
  userId: number;
};

export type SubGuestT = {
  guestEmail: string;
  guestId: number;
  guestName: string;
  promoCodes: PromoCodeT[];
};

export type CodeGroupGuestT = {
  codeGroupId: number;
  createdAt: string;
  guestEmail: string;
  guestName: string;
  id: number;
  outboundStatus: string;
  promoCodeIds: number[];
  updatedAt: string;
};

export enum CodeViewFields {
  name = 'name',
  email = 'email',
}

export type CodeViewStateT = {
  name?: string;
  email?: string;
  valid: boolean;
};

export type CodeViewStateChange = (obj: {key: CodeViewFields; id: string; value?: string}) => void;

export type GenerateCSVResT = {
  headers: string[];
  data: (string | null | undefined)[][];
};
