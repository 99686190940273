import React, {useRef, useState} from 'react';
import {
  CardDateInfoLabel,
  CardInfoRow,
  CardInfoValue,
  CardsRow,
  TableCardsWrapper,
  TableTitleCard,
  TableCardsMenuWrapper,
  UploadButtonsRow,
  DownloadCSVButton,
} from './styles';
import {format} from 'date-fns';
import {StaticCard} from '../../../ui-kit/StaticCard';
import {Button, Dropdown, Menu, MenuProps, Select} from 'antd';
import {DashboardCardT} from '../../../types/dashboard';
import {CodeStatusTypes, GenerateCSVResT} from '../../../types/codes';
import {SelectOptionT} from '../../../types/helpers';
import {User} from '../../../types/auth';
import {CSVLink} from 'react-csv';

type TableCardsProps = {
  cards?: DashboardCardT[];
  createdAt?: string | number;
  updatedAt?: string | number;
  filterValue?: CodeStatusTypes;
  setFilter?: (v: CodeStatusTypes) => void;
  filterOptions?: SelectOptionT[];
  onAddCodes?: () => void;
  onAddEmails?: () => void;
  user?: User | null;
  downloadCSV?: () => GenerateCSVResT;
  openResetModal?: () => void;
};

export const TableCards: React.FC<TableCardsProps> = ({
  cards,
  createdAt,
  updatedAt,
  filterValue,
  setFilter,
  filterOptions,
  onAddCodes,
  onAddEmails,
  downloadCSV,
  openResetModal,
}) => {
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}>(null);
  const [csv, setCsv] = useState<(string | null | undefined)[][]>([]);
  const onDownloadCSVclick = () => {
    const res = downloadCSV?.();
    if (!res?.headers || !res?.data) return;
    setCsv([res.headers, ...res.data]);
    csvLinkRef?.current?.link.setAttribute(
      'download',
      `code_list_view_${format(new Date(), 'P').replaceAll('/', '_')}`,
    );

    setTimeout(() => {
      csvLinkRef?.current?.link.click();
    }, 500);
  };

  const items: MenuProps['items'] = [
    ...(downloadCSV
      ? [
          {
            key: '1',
            label: <div onClick={onDownloadCSVclick}>Download CSV Report</div>,
          },
        ]
      : []),
    {
      key: '2',
      label: <div onClick={onAddEmails}>Upload Emails</div>,
    },
    {
      key: '3',
      label: <div onClick={onAddCodes}>Add New Codes</div>,
    },
    {
      key: '4',
      label: <div onClick={openResetModal}>Reset Group</div>,
    },
  ];
  return (
    <TableCardsWrapper>
      <CardsRow>
        <TableTitleCard>
          <CardInfoRow>
            <CardDateInfoLabel>Created</CardDateInfoLabel>
            <CardInfoValue>{createdAt && format(new Date(createdAt), 'd LLL u')}</CardInfoValue>
          </CardInfoRow>
          <CardInfoRow>
            <CardDateInfoLabel>Updated</CardDateInfoLabel>
            <CardInfoValue>{updatedAt && format(new Date(updatedAt), 'd LLL u')}</CardInfoValue>
          </CardInfoRow>
        </TableTitleCard>
        {cards?.map((el) => (
          <StaticCard key={el.label} {...el} />
        ))}
      </CardsRow>
      <TableCardsMenuWrapper>
        <UploadButtonsRow>
          {/* {downloadCSV && (
            <AddNewCodesButton variant={ButtonTypes.secondary} onClick={onDownloadCSVclick}>
              Download CSV
            </AddNewCodesButton>
          )}
          <AddNewCodesButton variant={ButtonTypes.secondary} onClick={onAddEmails}>
            Upload Emails
          </AddNewCodesButton>
          <AddNewCodesButton variant={ButtonTypes.secondary} onClick={onAddCodes}>
            Add New Codes
          </AddNewCodesButton> */}
          <Dropdown overlay={<Menu items={items} />} placement="bottomLeft" arrow>
            <Button>Actions</Button>
          </Dropdown>
        </UploadButtonsRow>
        <Select options={filterOptions} value={filterValue} onChange={setFilter} />
        <DownloadCSVButton data={csv} target="_blank" ref={csvLinkRef} />
      </TableCardsMenuWrapper>
    </TableCardsWrapper>
  );
};
