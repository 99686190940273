import {GroupDetailTableDataT, CodeStatusTypes, CodeSendStatuses} from '../types/codes';
import {validateEmail} from './codes';

export const getFilterTableData = (type: CodeStatusTypes, tableData?: GroupDetailTableDataT[]) => {
  switch (type) {
    case CodeStatusTypes.all:
      return tableData;

    case CodeStatusTypes.sent:
      return tableData?.filter((el, idx) => idx === 0 || el.actions?.codeStatus === CodeSendStatuses.sent);

    case CodeStatusTypes.pending:
      return tableData?.filter((el, idx) => idx === 0 || el.actions?.codeStatus === CodeSendStatuses.notsent);

    default:
      return tableData;
  }
};

export const getErrorResponse = (e: unknown) => {
  try {
    const jsonErr = JSON.parse(JSON.stringify(e));
    return jsonErr?.response?.text && JSON.parse(jsonErr?.response?.text);
  } catch (e) {
    return false;
  }
};

export const parseStringArray = (value?: string, separator?: string) =>
  value
    ?.replaceAll(' ', '')
    .split(separator || ',')
    ?.filter((el) => !!el);

export const validateEmailsArray = (arr?: string[]) => !arr?.some((el) => !validateEmail(el));
