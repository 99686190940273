import React from 'react';
import {useModalsFlow} from '../../hooks/onboarding';
import {OnboardingModals} from '../../components/CodeGroup/View/OnboardingModals';

export const Onboarding = () => {
  const {showOnboarding, choseBulk, choseIndividual, step, goMethods, goUpload, onFinish, onCloseFlowModals} =
    useModalsFlow();
  return (
    <OnboardingModals
      showOnboarding={showOnboarding}
      choseBulk={choseBulk}
      choseIndividual={choseIndividual}
      step={step}
      goMethods={goMethods}
      goUpload={goUpload}
      onFinish={onFinish}
      onCloseFlowModals={onCloseFlowModals}
    />
  );
};
