import React, {useEffect, useState} from 'react';
import {routePaths} from '../../constants/routes';
import {Footer} from '../../ui-kit/AppLayout/Footer';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {useGetCodeGroup, useShareCodes} from '../../hooks/codes';
import {ModalLvl} from '../../components/CodeGroup/View/Table';
import {ShareModal} from '../CodeGroup/ShareModal';
import {CodeAccessTypes} from '../../types/codes';
import {useSendNotSentEmails} from '../../hooks/emails';
import {CodeGroupT} from '../../queries/types/codes';

const StyledFooter = styled(Footer)<{$fixed?: boolean}>`
  ${({$fixed}) => ($fixed ? `position: fixed; bottom: 0; left: 0;` : `display: none;`)};
`;

type GroupFooterContainer = {
  onSuccess?: () => void;
  onBulk?: () => Promise<void>;
  sendCount?: string;
  outbound?: CodeAccessTypes | undefined;
  code?: CodeGroupT;
};

export const GroupFooterContainer: React.FC<GroupFooterContainer> = ({onSuccess}) => {
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [shareText, setShareText] = useState('');

  const {pathname} = useLocation();
  const isCodePage = pathname.includes(routePaths.viewCodeGroup);
  const id = isCodePage ? pathname.split('/')?.[2] : '';
  const {code, refetch} = useGetCodeGroup(id, !isCodePage);
  const handleSuccess = () => {
    onSuccess?.();
    refetch();
  };
  const {shareCode} = useShareCodes(id, handleSuccess);
  const onShareClick = () => setModalLvl(ModalLvl.confirm);
  const onShare = async () => await shareCode();

  useEffect(() => {
    setShareText(code?.outboundStatus === CodeAccessTypes.sent ? 'Reshare Access' : 'Share Access');
  }, [code?.outboundStatus]);
  const changeModalLvl = (lvl: ModalLvl) => setModalLvl(lvl);

  return (
    <>
      <ShareModal
        clientEmail={code?.clientEmail}
        clientEmailList={code?.clientEmailList}
        clientName={code?.clientName}
        modalLvl={modalLvl}
        setModalLvl={changeModalLvl}
        onSuccess={onShare}
        status={code?.outboundStatus}
      />
      <StyledFooter showReshare={true} buttonText={shareText} $fixed={true} onReshare={onShareClick} />
    </>
  );
};

export const InternalGroupFooterContainer: React.FC<GroupFooterContainer> = ({onSuccess, onBulk, sendCount, code}) => {
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [reshareModalLvl, setReshareModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const {pathname} = useLocation();
  const isGroupPage = pathname.includes(routePaths.viewCodeGroup);
  const id = isGroupPage ? pathname.split('/')?.[2] : '';
  const {send} = useSendNotSentEmails([id]);
  const onShareClick = () => setModalLvl(ModalLvl.confirm);
  const onShare = async () => {
    await send();
    await onBulk?.();
    onSuccess?.();
  };
  const showReshare = isGroupPage;
  const changeModalLvl = (lvl: ModalLvl) => setModalLvl(lvl);
  const totalQtyNotSentEmails = () => sendCount || '';

  //reshare access
  const showAdditional = true;
  const actionText = code?.outboundStatus === CodeAccessTypes.notsent ? 'Share Access' : 'Reshare Access';
  const {shareCode} = useShareCodes(id, onSuccess);
  const handleActionClick = () => setReshareModalLvl(ModalLvl.confirm);
  const onReshareShare = async () => await shareCode();
  const reshareChangeModal = (lvl: ModalLvl) => setReshareModalLvl(lvl);
  return (
    <>
      <ShareModal
        modalLvl={modalLvl}
        setModalLvl={changeModalLvl}
        onSuccess={onShare}
        totalQtyNotSentEmails={totalQtyNotSentEmails}
      />
      <ShareModal
        clientEmail={code?.clientEmail}
        clientEmailList={code?.clientEmailList}
        clientName={code?.clientName}
        modalLvl={reshareModalLvl}
        setModalLvl={reshareChangeModal}
        onSuccess={onReshareShare}
        status={code?.outboundStatus}
      />
      <StyledFooter
        showReshare={showReshare}
        buttonText={'Bulk Send Codes'}
        $fixed={showReshare}
        onReshare={onShareClick}
        showAdditional={showAdditional}
        actionText={actionText}
        handleAction={handleActionClick}
      />
    </>
  );
};

const FooterContainer = () => {
  return (
    <>
      <Footer />
    </>
  );
};
export default FooterContainer;
