import React, {useEffect, useState} from 'react';
import {
  ClearButton,
  CodesTable,
  CreatingLabel,
  EnterButton,
  MenuAction,
  ModalDescr,
  ResultLabel,
  SearchBtn,
  SearchInput,
  SearchResultWrapper,
  SearchWrapper,
} from './styles';
import {TableWrapper} from '../../ui-kit/Table';
import {DashboardTableDataT} from '../../types/dashboard';
import {
  DashboardCodeMap,
  DashboardTableColumnsTitles,
  DashboardTableFields,
  StatusTitleMap,
  TableColumnT,
} from '../../helpers/table';
import {ButtonMore, InfoButton} from '../../ui-kit/Button';
import {Dropdown, Menu, Modal} from 'antd';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {format} from 'date-fns';
import {Link} from 'react-router-dom';
import {route} from '../../constants/routes';
import {CloseOutlined, SearchOutlined, WarningOutlined} from '@ant-design/icons';

export enum popupLvl {
  closed = 'closed',
  confirm = 'confirm',
  success = 'success',
  confirmLeave = 'confirmLeave',
}
type DashboardTableProps = {
  data?: DashboardTableDataT[];
  goEdit: (id: string) => void;
  onDelete: (id: string) => void;
  loading?: boolean;
  canAddEdit?: boolean;
  searchCodes: (v: string) => void;
  metricsLoading?: boolean;
};

type SearchProps = {
  value: string;
  setValue: (v: string) => void;
  searchCodes: (v: string) => void;
  showResult: (v: boolean) => void;
  disabled?: boolean;
};

type SearchResultProps = SearchProps & {
  resQty?: number;
};

export const DashboardTable: React.FC<DashboardTableProps> = ({
  data,
  goEdit,
  onDelete,
  loading,
  canAddEdit,
  searchCodes,
  metricsLoading,
}) => {
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [isEditing, setIsEditing] = useState(false);
  const [delPopupLvl, setDelPopupLvl] = useState<popupLvl>(popupLvl.closed);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchRes, setIsSearchRes] = useState<boolean>(false);
  const setDelPopup = (lvl: popupLvl) => setDelPopupLvl(lvl);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (b: boolean) => {
    setIsEditing(b);
  };
  const getClientName = (id?: string) => {
    return data?.find((el) => el.id === id)?.clientName?.name;
  };

  useEffect(() => {
    if (delPopupLvl === popupLvl.confirm) {
      Modal.confirm({
        title: `Do you want to delete the code group for the ${getClientName(focusedId)}?`,
        content: (
          <ModalDescr>
            Once you have confirmed, the code group will be removed from the Portal, and you won’t be able to make any
            further edits.
          </ModalDescr>
        ),
        okText: 'Yes, proceed to delete',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => {
          setDelPopup(popupLvl.success);
          focusedId && onDelete(focusedId);
        },
        onCancel: () => setDelPopup(popupLvl.closed),
      });
    }
    if (delPopupLvl === popupLvl.success) {
      Modal.success({
        title: `The code group has been deleted!`,
        content: <ModalDescr>You will not see the code group on the Portal now.</ModalDescr>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setDelPopup(popupLvl.closed);
        },
      });
    }
  }, [delPopupLvl]);

  const columns: TableColumnT[] = [
    {
      key: DashboardTableFields.id,
      title: DashboardTableColumnsTitles.id,
      dataIndex: DashboardTableFields.id,
      render: (id) => {
        return id === 'creating' ? <></> : <Link to={route.viewCodeGroup.get({id: id})}>{id}</Link>;
      },
    },
    {
      key: DashboardTableFields.clientName,
      title: DashboardTableColumnsTitles.clientName,
      dataIndex: DashboardTableFields.clientName,
      render: (values: {id: string; name: string}) => {
        return values.id === 'creating' ? (
          <>{values.name}</>
        ) : (
          <Link to={route.viewCodeGroup.get({id: values.id})}>{values.name}</Link>
        );
      },
    },
    {
      key: DashboardTableFields.ticketType,
      title: DashboardTableColumnsTitles.ticketType,
      dataIndex: DashboardTableFields.ticketType,
    },
    {
      key: DashboardTableFields.department,
      title: DashboardTableColumnsTitles.department,
      dataIndex: DashboardTableFields.department,
    },
    {
      key: DashboardTableFields.contactPerson,
      title: DashboardTableColumnsTitles.contactPerson,
      dataIndex: DashboardTableFields.contactPerson,
    },
    {
      key: DashboardTableFields.createdOn,
      title: DashboardTableColumnsTitles.createdOn,
      dataIndex: DashboardTableFields.createdOn,
      render: (value: string) => {
        return <>{value && format(new Date(value), 'd LLL, Y')}</>;
      },
    },
    {
      key: DashboardTableFields.totalCodes,
      title: DashboardTableColumnsTitles.totalCodes,
      dataIndex: DashboardTableFields.totalCodes,
    },
    {
      key: DashboardTableFields.codesSent,
      title: DashboardTableColumnsTitles.codesSent,
      dataIndex: DashboardTableFields.codesSent,
    },
    {
      key: DashboardTableFields.status,
      title: DashboardTableColumnsTitles.status,
      dataIndex: DashboardTableFields.status,
      render: (status: DashboardTableDataT['status']) => {
        if (status?.isCreating) return <CreatingLabel>Creating</CreatingLabel>;
        return status?.groupType === 'external' ? (
          <InfoButton variant={status.type && DashboardCodeMap?.[status.type]}>
            {status.type && StatusTitleMap?.[status.type]}
          </InfoButton>
        ) : null;
      },
    },
    {
      key: DashboardTableFields.actions,
      dataIndex: DashboardTableFields.actions,
      title: (
        <Search
          searchCodes={searchCodes}
          showResult={setIsSearchRes}
          value={searchValue}
          setValue={setSearchValue}
          disabled={metricsLoading}
        />
      ),
      render: (props: DashboardTableDataT['actions']) => {
        return (
          <>
            {!props?.isCreating ? (
              <MenuMore
                id={props?.id}
                setEditing={setEditing}
                setDelPopup={setDelPopup}
                setFocusId={setFocusId}
                isEditing={isEditing}
                goEdit={goEdit}
                canAddEdit={canAddEdit}
              />
            ) : null}
          </>
        );
      },
    },
  ];

  const locale = {
    emptyText: <ResultLabel>There are no results found for your search.</ResultLabel>,
  };

  return (
    <>
      {isSearchRes && searchValue && (
        <SearchResult
          resQty={data?.length}
          showResult={setIsSearchRes}
          searchCodes={searchCodes}
          value={searchValue}
          setValue={setSearchValue}
        />
      )}
      <Modal />
      <TableWrapper>
        <CodesTable
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
          locale={isSearchRes && !data?.length ? locale : undefined}
        />
      </TableWrapper>
    </>
  );
};

type MenuMoreProps = {
  isEditing: boolean;
  id?: string;
  setDelPopup?: (lvl: popupLvl) => void;
  setFocusId?: (id?: string) => void;
  setEditing?: (b: boolean) => void;
  goEdit?: (id: string) => void;
  canAddEdit?: boolean;
};

export const MenuMore: React.FC<MenuMoreProps> = ({id, setDelPopup, setEditing, setFocusId, goEdit, canAddEdit}) => {
  const onEdit = () => {
    setFocusId?.(id);
    setEditing?.(true);
    setDelPopup?.(popupLvl.closed);
    id && goEdit?.(id);
  };
  const onDelete = () => {
    setFocusId?.(id);
    setEditing?.(false);
    setDelPopup?.(popupLvl.confirm);
  };

  const ddActions: ItemType[] = canAddEdit
    ? [
        {key: 1, label: <MenuAction onClick={onEdit}>Edit</MenuAction>},
        {
          key: 2,
          label: (
            <MenuAction $danger onClick={onDelete}>
              Delete
            </MenuAction>
          ),
        },
      ]
    : [];
  if (!canAddEdit) return null;
  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore />
    </Dropdown>
  );
};

export const Search: React.FC<SearchProps> = ({value, setValue, searchCodes, showResult, disabled}) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  const handleOnSearch = () => {
    searchCodes(value);
    setOpen(false);
    showResult(true);
  };

  const searchInput = (
    <SearchWrapper>
      <SearchInput
        prefix={<SearchOutlined />}
        placeholder="Search by client name or contact person..."
        onChange={handleInputChange}
        onPressEnter={handleOnSearch}
        value={value}
      />
      <EnterButton onClick={handleOnSearch} disabled={value === ''}>
        Search
      </EnterButton>
    </SearchWrapper>
  );

  return (
    <SearchBtn disabled={disabled}>
      <Dropdown
        overlay={searchInput}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={handleOpenChange}
        visible={open}>
        {open ? <CloseOutlined /> : <SearchOutlined />}
      </Dropdown>
    </SearchBtn>
  );
};

const SearchResult: React.FC<SearchResultProps> = ({resQty, showResult, value, setValue, searchCodes}) => {
  const onClearClick = () => {
    setValue('');
    searchCodes('');
    showResult(false);
  };

  return (
    <SearchResultWrapper>
      <ResultLabel>{`${resQty} Results for "${value}"`}</ResultLabel>
      <ClearButton onClick={onClearClick}>Clear Search</ClearButton>
    </SearchResultWrapper>
  );
};
