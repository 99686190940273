import {useEffect, useState} from 'react';
import {
  createDepartment,
  createPermission,
  createRole,
  deleteDepartment,
  deletePermission,
  deleteRole,
  deleteUser,
  getAllUsers,
  getListDepartments,
  getListPermissions,
  getListRoles,
  inviteUser,
  updateDepartment,
  updateUser,
} from '../queries/settings';
import {CreateDepartmentField, CreatePermissionField, DepartmentT, PermissionT, RoleT} from '../queries/types/settings';
import {
  departmentsToTableData,
  departmentToCreateData,
  departmentToOptions,
  getAccessOptions,
  permissionsToTableData,
  permissionToCreateData,
  rolesToOptions,
  rolesToTableData,
  roleToCreateData,
  usersToTableState,
  userToCreateBody,
} from '../helpers/settings';
import {CreatingUserFields, CreatingUserStateT, ManageTableItemT} from '../types/settings';
import {User} from '../types/auth';
import {getPermissions} from '../helpers/roles';
import {useViewer} from './auth';

// <------- PERMISSIONS ----------
export const useGetPermissions = () => {
  const [permissions, setPermissions] = useState<PermissionT[]>([]);
  const [loading, setLoading] = useState(false);
  const fetch = async () => {
    setLoading(true);
    try {
      const res = await getListPermissions();
      if (res.body) setPermissions(res.body as PermissionT[]);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, []);
  return {permissions, tableData: permissionsToTableData(permissions), refetch: fetch, loading};
};
export const useCreatePermission = (onSuccess?: () => void) => {
  const create = async (values: any) => {
    try {
      const body = permissionToCreateData(values);
      if (!body[CreatePermissionField.name]) return false;
      const res = await createPermission({body});
      if (res.body) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  return {create};
};

export const useDeletePermission = (onSuccess?: () => void) => {
  const onDelete = async (permissionId?: string) => {
    try {
      if (!permissionId || Number(permissionId) === 1) return false;
      const res = await deletePermission({permissionId});
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  return {onDelete};
};
export type updatePermissionValues = {permissionId?: string; values: any};

// -------- PERMISSIONS --------->

// <------- ROLES ----------

export const useGetRoles = () => {
  const [roles, setRoles] = useState<RoleT[]>([]);
  const [loading, setLoading] = useState(false);
  const fetch = async () => {
    setLoading(true);
    const res = await getListRoles();
    if (res.body) setRoles(res.body as RoleT[]);
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, []);
  return {roles, tableData: rolesToTableData(roles), options: rolesToOptions(roles), refetch: fetch, loading};
};

export const useCreateRole = (onSuccess?: () => void) => {
  const create = async (values: any) => {
    try {
      const body = roleToCreateData(values);
      if (!body[CreatePermissionField.name]) return false;
      const res = await createRole({body});
      if (res.body) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  return {create};
};

export const useDeleteRole = (onSuccess?: () => void) => {
  const onDelete = async (roleId?: string) => {
    try {
      if (!roleId) return false;
      const res = await deleteRole({roleId});
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  return {onDelete};
};
// -------- ROLES --------->

// <------- DEPARTMENTS -----------
export const useGetDepartments = () => {
  const viewer = useViewer();
  const [departments, setDepartments] = useState<DepartmentT[]>([]);
  const [loading, setLoading] = useState(false);
  const {isSuperAdmin} = getPermissions();
  const fetch = async () => {
    setLoading(true);
    if (!isSuperAdmin) {
      setDepartments(viewer?.departments?.map((el, i) => ({name: el, id: i})) || []);
      setLoading(false);
      return;
    }
    const res = await getListDepartments();
    if (res?.body) setDepartments(res?.body as DepartmentT[]);
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, []);
  return {
    departments,
    tableData: departmentsToTableData(departments),
    options: departmentToOptions(departments),
    refetch: fetch,
    loading,
  };
};

export const useCreateDepartments = (onSuccess?: () => void) => {
  const create = async (values: any) => {
    try {
      const body = departmentToCreateData(values);
      if (!body[CreateDepartmentField.name]) return false;

      const res = await createDepartment({body});
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  return {create};
};

export const useDeleteDepartments = (onSuccess?: () => void) => {
  const onDelete = async (departmentId?: string) => {
    try {
      if (!departmentId) return false;
      const res = await deleteDepartment({departmentId});
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  return {onDelete};
};

export type updateDepValues = {departmentId?: string; values: any};
export const useUpdateDepartments = (onSuccess?: () => void) => {
  const update = async ({departmentId, values}: updateDepValues) => {
    try {
      const body = departmentToCreateData(values);
      if (!body[CreateDepartmentField.name] || !departmentId) return false;

      const res = await updateDepartment({departmentId, body});
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  return {update};
};
// -------- DEPARTMENTS ---------->

//<------USER MANAGE---------------
export const useGetUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [nonFilteredUsers, setNonFilteredUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const {options: depOptions} = useGetDepartments();
  const {options: rolesOptions} = useGetRoles();
  const accessOptions = getAccessOptions();

  const fetch = async () => {
    setLoading(true);
    try {
      const res = await getAllUsers();
      setUsers(res?.body as User[]);
      setNonFilteredUsers(res?.body as User[]);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const filterUsers = (options: string[]) => {
    if (users) {
      if (!options.length) {
        setUsers(nonFilteredUsers);
        return;
      }

      const deps = depOptions.map((e) => typeof e.value === 'string' && e.value?.toLowerCase());
      const roles = rolesOptions.map((e) => typeof e.value === 'string' && e.value?.toLowerCase());
      const portals = accessOptions.map((e) => e.value.toLowerCase());

      const optionsByType = options.reduce(
        (acc: {deps: string[]; roles: string[]; portals: string[]}, opt: string) => {
          const lowOption = opt.toLowerCase();

          if (deps.includes(lowOption)) {
            acc.deps.push(lowOption);
          } else if (roles.includes(lowOption)) {
            acc.roles.push(lowOption);
          } else if (portals.includes(lowOption)) {
            acc.portals.push(lowOption);
          }

          return acc;
        },
        {deps: [], roles: [], portals: []},
      );

      const result = nonFilteredUsers.filter((user) => {
        const departmentsLow = user.departments?.map((dep) => dep.toLowerCase());
        const portalsLow = user.portals?.map((portal) => portal.toLowerCase());
        const rolesLow = user.roles?.map((role) => role.toLowerCase());

        if (
          optionsByType.deps.every((opt) => departmentsLow?.includes(opt)) &&
          optionsByType.roles.every((opt) => rolesLow?.includes(opt)) &&
          optionsByType.portals.every((opt) => portalsLow?.includes(opt))
        ) {
          return true;
        }

        return false;
      });

      setUsers(result);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {users, tableData: usersToTableState(users), loading, refetch: fetch, filterUsers};
};

export type useCreatingUserT = {
  handleChange: (v: {name: CreatingUserFields; value?: string}) => void;
  values: CreatingUserStateT;
  onSubmit: () => void;
  createLoading?: boolean;
};

export const useCreatingUser = (onSuccess?: () => void): useCreatingUserT => {
  const [values, setValues] = useState<CreatingUserStateT>({});
  const [loading, setLoading] = useState(false);
  const handleChange = ({name, value}: {name: CreatingUserFields; value?: string}) =>
    setValues((prev) => ({...prev, [name]: value}));

  const onSubmit = async () => {
    const body = userToCreateBody(values);
    if (!body) return false;
    try {
      setLoading(true);
      const res = await inviteUser({body});
      setLoading(false);
      if (res) {
        onSuccess?.();
        setValues({departmentId: '', roleId: '', access: []});
      }
    } catch (e) {
      setLoading(false);
    }
  };
  return {handleChange, values, onSubmit, createLoading: loading};
};

export type useManageUserActionsT = {
  onDelete: (id?: string) => void;
  deleteLoading?: boolean;
};
export const useManageUserActions = (onSuccess?: () => void): useManageUserActionsT => {
  const [loading, setLoading] = useState(false);

  const onDelete = async (id?: string) => {
    if (!id) return false;
    try {
      setLoading(true);
      const res = await deleteUser(id);
      setLoading(false);
      if (res) {
        onSuccess?.();
      }
    } catch (e) {
      setLoading(false);
    }
  };
  return {onDelete, deleteLoading: loading};
};

export type useEditingUserT = {
  handleChange: (v: {name: CreatingUserFields; value?: string}) => void;
  values: CreatingUserStateT;
  onUpdate: () => void;
  updateLoading?: boolean;
};

export const useEditingUser = (
  initalState?: ManageTableItemT,
  focusId?: string,
  onSuccess?: () => void,
): useEditingUserT => {
  const [values, setValues] = useState<CreatingUserStateT>({});
  const [loading, setLoading] = useState(false);
  const handleChange = ({name, value}: {name: CreatingUserFields; value?: string}) =>
    setValues((prev) => ({...prev, [name]: value}));

  useEffect(() => {
    const state: CreatingUserStateT = {
      email: initalState?.email?.email || '',
      name: initalState?.fullName?.fullName || '',
      roleId: initalState?.role?.roleId || '',
      departmentId: initalState?.department?.departmentId || '',
      access: initalState?.access?.access || [],
    };
    setValues(state);
  }, [focusId]);
  const onUpdate = async () => {
    const body = userToCreateBody(values);
    if (!body || !focusId) return false;
    try {
      setLoading(true);
      const res = await updateUser({id: focusId, body});
      setLoading(false);
      if (res) {
        onSuccess?.();
        setValues({});
      }
    } catch (e) {
      setLoading(false);
    }
  };
  return {handleChange, values, onUpdate, updateLoading: loading};
};
//------USER MANAGE-------------->
